import { useEffect, useState } from 'react';
import config from '../../config/index';
import AuthObj from '../../utility/authclass';

import ReportObj from '../../utility/reportclass';
import CustomerObj from '../../utility/customerclass';
import { Button, Pagination, Popconfirm, notification, Modal, Empty, AutoComplete, Input, DatePicker,Spin,Divider } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import commonclass from '../../utility/commonclass';
const photofield = ["tp_uq_pole_photo", "tp_uq_pole_paint_photo"];
const { RangePicker } = DatePicker;

const searchResult = (data) => {

    return data.map((item, itemkey) => {
        return {
            value: item.first_name+' '+item.last_name+' '+item.mobile, label: (
                <>{`${item.first_name} ${item.last_name} (${item.mobile})`}</>
            ),
            key: item.id,
            user:item
        }
    })
}

const List = () => {
    const [customerList, setReportList] = useState({ totalrecord: 0, list: [], uploadurl: "" });
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 10 });
    const [filter, setFilter] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageData, setImageData] = useState({ url: "", list: [],pastedimage:[] });
    const [useroptions, setuseroptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loader, setloader] = useState(false);
    const [downloadtype, setdownloadtype] = useState("geojson");
    const [currentUser, setCurrentUser] = useState({});
    const [section,setsection]=useState({list:[],options:[]})
    const [layers, setLayers] = useState([]);
    const [kv33,setkv33]=useState([])
    const [kv11,setkv11]=useState([])
    const [dtlist,setdtlist]=useState([])
    const [feederdata, setfeederdata] = useState({});
    const [selectedItem, setselectedItem] = useState({});
    const [selectedItemKey, setselectedItemKey] = useState(null);
    
    const [modalform,setmodalform]=useState({});

    let navigate = useNavigate();
    useEffect(() => {
        console.log('call customer useEffect');
        ReportObj.getList({ page: paging.currentpage, limit: paging.itemlimit, filter: filter}).then(list => {
            setReportList(() => {
                return { totalrecord: list?.collection?.count, list: list?.collection?.rows, uploadurl: list.uploadurl };
            })
        });
    }, [paging]);

    useEffect(() => {
        async function getLayers() {

            const list = await ReportObj.getCollectionLayer({status:1});
            console.log(list);
            setLayers(list);
        }

        async function getCurrentUser() {

            const user = await AuthObj.getUserSession();
            console.log('User');
            console.log(user);
            setCurrentUser(user?.user);
         //   setLayers(list);
        }
        getCurrentUser();
        getLayers();
        getSection();
        getFeederList();
    }, []);
    
// For show Name in List
    async function getFeederList() {
        const flist = await CustomerObj.getkvfeeder11({});
          
          let filterdata=  flist.reduce((pre,current)=>{
              pre[current?.id]=current;
            return pre;

            },{});
          
            setfeederdata(filterdata);
            
       
      }
    async function getSection() {
        const sectionlist = await CustomerObj.getSection({});
  
  
        
        const worktypefiletrlist = sectionlist.map((item) => {
          return { value: item.id, label: `${item.section_name} (${item.section_code})` }
  
        })

        setsection({list:sectionlist,options:worktypefiletrlist});
        
      }

      async function getkvfeeder33(section_id) {
        const kvfeeder33list = await CustomerObj.getkvfeeder33({sectionid:section_id});             
        setkv33(kvfeeder33list);
      }

      async function getkvfeeder11(kvfeeder33_id) {
        const kvfeeder11list = await CustomerObj.getkvfeeder11({kvfeeder33_id:kvfeeder33_id});       
        setkv11(kvfeeder11list);
      }

      async function getTransformer(kvfeeder11_id) {
        const transformerlist = await CustomerObj.getTransformer({kvfeeder11_id:kvfeeder11_id});
        setdtlist(transformerlist);
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        setpage((pre) => {
            return { ...pre, currentpage: 1 }
        })
      /*  getUsers({ page: paging.currentpage, limit: paging.itemlimit, filter: filter }).then(list => {
            setReportList(() => {
                return { totalrecord: list.count, list: list.rows };
            })

        });*/
    }

    function getFeederName(id=0){
        
        if(common.isEmpty(feederdata[id])==false){

            return feederdata[id]?.feeder_name;
        }
        return "";
    }

    function getFeederCode(id=0){
        
        if(common.isEmpty(feederdata[id])==false){

            return feederdata[id]?.feeder_code;
        }
        return "";
    }

    const handleChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(name=='section_id'){
            setkv33([]);
            setkv11([]);
            setdtlist([]);
            setFilter((pre) => {                
                let newobj = {};
                newobj[name] = value;
                newobj['kvfeeder33_id'] = '';
                newobj['kvfeeder11_id'] = '';
                newobj['dt_id'] = '';
                
                console.log({ ...pre, ...newobj });

                return { ...pre, ...newobj }
    
            })
            
          await getkvfeeder33(value);
          return true;
        }

        if(name=='kvfeeder33_id'){         
            setkv11([]);
            setdtlist([]);

            setFilter((pre) => {                
                let newobj = {};
                newobj[name] = value;               
                newobj['kvfeeder11_id'] = '';
                newobj['dt_id'] = '';
                console.log({ ...pre, ...newobj });
                return { ...pre, ...newobj }
    
            })
          await getkvfeeder11(value);
          return true;
        }
        if(name=='kvfeeder11_id'){         
           
            
            setdtlist([]);
            setFilter((pre) => {
                console.log(pre);
                let newobj = {};
                newobj[name] = value;
                newobj['dt_id'] = '';
                return { ...pre, ...newobj }
    
            })
            await getTransformer(value);
            return true;
          //await getkvfeeder33();
        }

        

        

        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }

        })
    }
    const handlePageChange = (page) => {

        setpage((pre) => {
            return { ...pre, currentpage: page }
        })
    }
    const handleDelete = async (e, id, key) => {
        try{
            console.log(id);
            const customerinfo= await ReportObj.deleteRecord({id:id});
            customerList.list.splice(key, 1);
            let newarr = customerList.list;            
            setReportList((preList) => {
                return { totalrecord: customerList.totalrecord - 1, list: customerList.list };
            })
            
        }catch(err){
            notification.error({message:err.message});

        }

    }

    const haldelEdit = (e, id) => {
        navigate('/admin/report/list/' + id);

    }

    const ImageField = (items) => {
        const photoData = items.filter((item) => {

            return photofield.indexOf(item.field_name) !== -1;
        })

        console.log(photoData);
        return (<>
            {photoData.map((photoItem) => {
                return (<><p><a href={`${customerList.uploadurl}${photoItem.field_value}`} target="_blank">{photoItem.field_label}</a></p></>)

            })}
        </>);
    }

    const openImage = async (id,key) => {
       setImageData({ url: "", list: [],pastedimage:[] });
       let item= {...customerList.list[key]};
       console.log(item);
       setselectedItem(item);
       setselectedItemKey(key);
       let images=[];
       let paintimages=[];
       if(item?.images !=null){
        images= JSON.parse(item?.images);
       }

       if(item?.afterPaintImage !=null){
        paintimages= JSON.parse(item?.afterPaintImage);
       }
       
       let approval="";
       if(item?.approval=="0"){
        approval="reject";
       }else if(item?.approval=="1"){
        approval="approval";
       }

       let ispasted="Pending";
       if(item?.ispasted=="0"){
        ispasted="Not Pasted";
       }else if(item?.ispasted=="1"){
        ispasted="Pasted";
       }
       
       setmodalform({remark:item?.remark,approval:approval,ispasted:ispasted})

       

       setImageData({ url: imageData.UPLOADPATH, list: images,pastedimage:paintimages });
     setIsModalOpen(true);
    /*    setImageData({ url: "", list: [],pastedimage:[] });
        const imageData = await ReportObj.getImageFields({ id: id });
        console.log(imageData);

        setImageData({ url: imageData.UPLOADPATH, list: imageData.fields,pastedimage:imageData?.paintimages });

        setIsModalOpen(true);*/
    }



    const handleOk = async() => {
        if(selectedItem?.user_id == 0){
            setIsModalOpen(false);
            return true;
        }

         if(commonclass.isEmpty(modalform?.approval)==true){
            alert("Please Select Approval Status");
            return false;
         }else if(modalform?.approval=="reject" && commonclass.isEmpty(modalform?.remark)==true){
            alert("Please Enter Remark");
            return false;
         }

    let arg={}
    arg['id']=selectedItem?.id;
    arg['approval']=(modalform?.approval=='approval')?1:0;
    arg['approval_by']=currentUser?.id;
    arg['remark']=modalform?.remark;
    await CustomerObj.surveyApproval(arg);
    let list=[...customerList.list];
    list[selectedItemKey]['approval']= arg['approval'];
    setReportList((pre)=>({...pre,list:list}));
         setmodalform({});

    setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handleSearch = (value,index) => {
        console.log(value);
        let filter = { searchkey: value };
        setloader(true);
        CustomerObj.getCustomers({ filter: filter }).then((data) => {
            setloader(false);
            console.log(searchResult(data.rows));
            setuseroptions(value ? searchResult(data.rows) : []);
        }).catch((err) => {

        })
    };

    const handleUserChange = (value) => {
        console.log('handleUserChange');
        console.log(value);
        if(value==''){
            filter['user_id']="";
        }
        setSearch(value);
    }

    const onSelect = (value,item) => {
           console.log(`Value ${value} index ${item}`);
           console.log(item);
        let name = 'user_id';
      
        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = item?.user?.id;
            return { ...pre, ...newobj }

        })
        console.log('onSelect', value);

    };


    const onChangeDate = (dates, dateStrings) => {
        console.log(dates);
        if (dates) {
            setFilter((pre) => {
              
                let newobj = {};
                newobj['startdate'] =  dateStrings[0];
                newobj['enddate'] =  dateStrings[1];
                return { ...pre, ...newobj }
    
            })
          
        } else {

            setFilter((pre) => {
              
                let newobj = {};
                newobj['startdate'] =  "";
                newobj['enddate'] =  "";
                return { ...pre, ...newobj }
    
            })
        }
      };

const downloadData =async (e)=>{

    //console.log(filter);
   // console.log(downloadtype);
    if(filter?.kvfeeder11_id==null || filter?.kvfeeder11_id==undefined){
        alert("Please Select Feeder");
        return false;
    }
    let fliteropt={...filter};
    fliteropt['fileType']=downloadtype;
    let qtr= new URLSearchParams(fliteropt).toString()
    console.log(qtr);
    console.log('Download');
    console.log(config.url+'downloadreport/?'+qtr);
    window.open(config.url+'downloadreport/?'+qtr, '_blank');
 
   // const list = await ReportObj.downloadReport({filter:filter,fileType:downloadtype});
   // console.log(list);
   // window.open(list.fileurl); 
 // window.location.replace(config.url+'downloadreport/?'+qtr);

}

const updateStatus=async(id,status,itemKey)=>{   
    let arg={}
    arg['id']=id;
    arg['approval']=status;
    arg['approval_by']=currentUser?.id;
    await CustomerObj.surveyApproval(arg);

    let list=[...customerList.list];
    list[itemKey]['approval']=status;
    console.log(list[itemKey]['approval']);
   // list[itemKey]?.approval=status;

    setReportList((pre)=>({...pre,list:list}));


}

const modalformHandel=(e)=>{
    console.log(e.target.name);
    console.log(e.target.value);
    setmodalform((pre)=>({...pre,[e.target.name]:e.target.value}))
}

    return (
        <>
            <div class="callout callout-info bg-white">

                <form class="row align-items-center" onSubmit={(e) => { handleSubmit(e) }}>
                <div class="col-md-2">
                        <label><strong>Code</strong></label>
                        <input type="text" className="form-control" name="code" onChange={(e)=>handleChange(e)}></input>
                    </div>
                    <div class="col-md-2">
                        <label><strong>User</strong></label>
                        <AutoComplete

                            style={{
                                width: "100%",

                            }}
                            options={useroptions}
                            onSelect={onSelect}
                            onSearch={handleSearch}
                            onChange={handleUserChange}
                            value={search}
                        >
                            <Input size="large" placeholder="User Search here" />

                        </AutoComplete>
                    </div>

                    <div class="col-md-2">
                        <label><strong>Layer</strong></label>
                        <select className="form-control" name="ext_id" onChange={(e)=>handleChange(e)}>
                            <option value="">Select </option>
                            {layers.map((item) => {

                                return (<option value={item.id}>{item.name}</option>)
                            })}
                        </select>
                    </div>                  
                    <div class="col-md-3">
                        <label><strong>Date</strong></label>
                        <div class="form">
                        <RangePicker
      ranges={{
        Today: [moment(), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      }}
      onChange={onChangeDate}
    />
                        </div>
                    </div>

                    <div class="col-md-2">
                        <label><strong>Section</strong></label>
                        <div class="form">
                            <select class="form-select" name="section_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose Section</option>
                                {section.list.map((item)=>{

                                    return <option value={item.id}>{item.section_name}</option>
                                })}                              
                            </select>
                        </div>
    </div>
    <div class="col-md-2">
                        <label><strong>33 KV</strong></label>
                        <div class="form">
                            <select class="form-select" name="kvfeeder33_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose 33KV</option>
                                {kv33.map((item)=>{
                                    return ( <option value={item.id} key={item.id}>{`${item.feeder_name} (${item.feeder_code})`}</option>)
                                })}                                
                            </select>
                        </div>
    </div>

    <div class="col-md-2">
                        <label><strong>11 KV</strong></label>
                        <div class="form">
                            <select class="form-select" name="kvfeeder11_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose 11 KV</option>
                                {kv11.map((item)=>{
                                    return ( <option value={item.id} key={item.id}>{`${item.feeder_name} (${item.feeder_code})`}</option>)
                                })}  
                            </select>
                        </div>
    </div>
    <div class="col-md-2">
                        <label><strong>Is Pasted</strong></label>
                        <div class="form">
                            <select class="form-select" name="pasted" onChange={(e) => handleChange(e)}>
                                <option value="">Choose</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                            </div>

                             <div class="col-md-2">
                        <label><strong>Approval</strong></label>
                        <div class="form">
                            <select class="form-select" name="approval" onChange={(e) => handleChange(e)}>
                                <option value="">Choose</option>
                                <option value="approval">Approval</option>
                                <option value="reject">Reject</option>
                                <option value="pending">Pending</option>
                            </select>
                        </div>
                            </div>
   {/* <div class="col-md-2">
                        <label><strong>Dt</strong></label>
                        <div class="form">
                            <select class="form-select" name="dt_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose DT</option>
                                {dtlist.map((item)=>{
                                    return ( <option value={item.id} key={item.id}>{`${item.transformer_code}`}</option>)
                                })} 
                            </select>
                        </div>
                            </div> */}



                    <div class="col-md-1">
<br/>
                        <button className="btn btn-primary" >Search</button>                       
                    </div>
    {currentUser.role !=3 && <>
                    
                       <div class="col-md-1">
                        <br/>
                                <select class="form-select" name="type"  onChange={(e) => setdownloadtype(e.target.value)}>                               
                                    
                                     <option value="geojson">geojson</option>
                                     {filter?.ext_id > 0 && <> <option value="csv">CSV</option>                                        
                                    <option value="shapefile">Shapefile</option> </>  }                            
                                </select>
                                </div>
                        <div class="col-md-2">
                        <br/>{/**/}
                        <Link className="btn btn-primary" to="" onClick={()=>downloadData()}>Download</Link>
                        </div></>
                   
                }
                    
                </form>
            </div>
            <div className="card mb-4">
                <div className="card-header">Collection List</div>
                <div className="card-body">
                    <div className="tab-content rounded-bottom table-responsive">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th>User</th>
                                    <th>Feeder Name</th>
                                    <th>Feeder Code</th>
                                    <th>Photo</th>
                                    <th width="100px">Number</th>
                                   {/* <th>Layer</th>*/}
                                   
                                    <th>Approval</th>
                                    <th>Server Date</th>
                                    <th>Survey Date</th>
                                    <th>Paint Code</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customerList.list.map((item, itemKey) => {
                                        return (
                                            <tr key={item.id}>
                                                <td >{((paging.currentpage - 1) * paging.itemlimit) + (itemKey + 1)}</td>

                                                <td >{
                                                item?.customer !=null &&
                                                `${item?.customer?.first_name} ${item?.customer?.last_name}`}
                                                {
                                                item?.customer ==null &&
                                                `Not Assign`}
                                                </td>
                                        <td >{getFeederName(item?.kvfeeder11_id)}</td>
                                        <td >{getFeederCode(item?.kvfeeder11_id)}</td>
                                                <td><button onClick={() => openImage(item.id,itemKey)}>View Photo</button></td>
                                                <td ><div className="record-div">{(item.record_number) ? item.record_number : 'Temp : ' + item.mobile_record_number}</div></td>
                                               {/* <td>{item?.objectextname?.name}</td>*/}
                                               
                                        <td>
                                            {item?.approval==null && 'No Action'}
                                            {item?.approval==1 && 'Approval'}
                                            {item?.approval==0 && 'Reject'}
                                           </td>                                               
                                        <td>{common.datetimeFormat(item.date)}</td>
                                        <td >{common.datetimeFormat(item.app_save_date)}</td>
                                        <td>{item?.paint_code}</td>
                                                <td >
                                                {1==2 && currentUser.role !=3 && item?.user_id > 0 &&  <>
                                                    <Link to={''} onClick={(e)=>{updateStatus(item?.id,'1',itemKey)}} className="btn btn-small btn-dark"> Approve</Link>
                                                    <Link to={''} onClick={(e)=>{updateStatus(item?.id,'0',itemKey)}} className="btn btn-small btn-danger"> Reject</Link>
                                                    {/*<Button icon={<EditOutlined />} onClick={(e) => haldelEdit(e, item.id)}>Edit</Button>*/} &nbsp;
                                                
                                              
     </>}
                                                </td>
                                               
                                            </tr>

                                        )

                                    })
                                }
                            </tbody>
                        </table>
                        <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={customerList.totalrecord} onChange={handlePageChange} />

                        <Modal title="Images" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                            {imageData.list.map((item) => {
                                let imageUrl="";
                                if(common.isEmpty(item)==false){
                                    imageUrl=item;
                                }else{
                                    imageUrl=`${item}`;
                                }

                                   
                                        return (<div className="photodis"><a href={imageUrl} target="_blank"><img style={{ width: 200 }} src={imageUrl}></img></a></div>)
                                   
                                
                            })}
                            {imageData.pastedimage?.length > 0 && <h5>Paint Image</h5>}
                            {imageData.pastedimage?.length > 0 && imageData.pastedimage?.map((item) => {
                                let imageUrl="";
                                if(common.isEmpty(item)==false){
                                    imageUrl=item;
                                }else{
                                    imageUrl=`${item}`;
                                }

                                   
                                        return (<div className="photodis"><a href={imageUrl} target="_blank"><img style={{ width: 200 }} src={imageUrl}></img></a></div>)
                                   
                                
                            })}
                            <Divider></Divider>
                            <div className="row">
                                <div className="col-md-4"><strong>Is Pasted</strong></div>
                                <div className="col-md-4">{(modalform['ispasted'])==undefined?"Pending":modalform['ispasted']}</div>
                            </div>
                            <Divider></Divider>
                            <div className="row"><div className="col-md-4"><strong>Paint Code</strong></div><div className="col-md-4"><label>{selectedItem?.paint_code}</label></div></div>
                         {selectedItem?.user_id > 0 &&   <> <Divider></Divider>
                            <div className="row">
                                <div className="col-md-4"><strong>Approval</strong></div>
                                <div className="col-md-4"><select class="form-select" name="approval" value={modalform['approval']} onChange={(e) => {modalformHandel(e)}}>
                                <option value="">Choose</option>
                                <option value="approval">Approval</option>
                                <option value="reject">Reject</option></select></div>
                            </div>
                            <Divider></Divider>
                            <div className="row">
                                <div className="col-md-4"><strong>Remark</strong></div>
                                <div className="col-md-4"><textarea name="remark" onChange={(e) => {modalformHandel(e)}} value={modalform['remark']}></textarea></div>
                            </div>
                            </>}

                            {imageData.list.length == 0 && imageData?.pastedimage?.length ==0 && <Empty></Empty>}
                        </Modal>

                    </div>
                </div>
            </div>
        </>
    )

}

export default List;