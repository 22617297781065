import { useEffect, useState } from 'react';
import { Button, Pagination, Popconfirm , AutoComplete,notification } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import reportObj from '../../utility/reportclass';
import { useNavigate,Link } from "react-router-dom";

const DivisionList = () => {

    const [list, setlist] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
      async function getData (){
        const divisionlist=await reportObj.getDivision({});
      
        setlist(divisionlist);
       }
      
       getData();
       

    }, []);


    const handleDelete = async (e, id, key) => {
        console.log(id);
       try{
            const customerinfo= await reportObj.deleteCollectionLayer({id:id});
            list.splice(key, 1);
            let newarr = list;
            setlist(newarr);           

        }catch(err){
            notification.error({message:err.message});

        }
       
    }



    return (
        <>
          
            <div className="card mb-4">
                <div className="card-header">Division List</div>
                <div className="card-body">
                   
                    <div className="tab-content rounded-bottom">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th >#</th>                                   
                                    <th>Division</th>
                                    <th>Code</th>                                   
                                    <th>Action</th>
                                                                   
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((item, itemKey) => {
                                        return (
                                                 <tr key={item.id}>
                                                    <td >{(itemKey + 1)}</td>
                                                    
                                                    <td >{item.division_name}</td>
                                                    <td >{item.division_code}</td>
                                                    <td ><Button type="primary" size="small"><Link to={`${item.id}`}>Edit</Link></Button>

                                                  {/*  <Popconfirm placement="bottom" title="Do You Want To Delete ?" onConfirm={(e) => handleDelete(e, item.id, itemKey)} okText="Yes" cancelText="No">
                                                        <Button icon={<DeleteOutlined />}>Delete</Button>
                                        </Popconfirm>*/}
</td>                                                   
                                               
                                                 </tr>

                                        )

                                    })
                                }

                            </tbody>
                        </table>
                        
                    </div>
                </div>
            </div>
        </>
    )

}

export default DivisionList;