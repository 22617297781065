import axios from '../config/axios';
import { notification } from 'antd';

function getResponseHandler(response) {

    if (response.data.status === 1) {
        
        return response.data.data;
    } else {
        notification.error({ message: response.data.errormessage });
        return [];
    }

}
export const getUsers = async (data = {}) => {

    try {
        const rowData = await axios.post('/user/getuser', data);
        const response = await rowData;
        return getResponseHandler(response);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const getUserDetail = async (id=0) => {

    try {
        const rowData = await axios.post('/user/getuserdetail', {id:id});
        const response = await rowData;
        return getResponseHandler(response);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}


export const AddUserService = async (data) => {

    try {
        const rowData = await axios.post('/user/add', data);
        const response = await rowData;

        if (response.data.status === 1) {
            notification.success({ message: response.data.message });
            return response.data.data;
        } else {
            notification.error({ message: response.data.errormessage });
            return {};
        }

        //return getResponseHandler(response);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const UpdateUserService = async (data) => {

    try {
        const rowData = await axios.post('/user/update', data);
        const response = await rowData;

        if (response.data.status === 1) {
            notification.success({ message: response.data.message });
            return response.data.data;
        } else {
            notification.error({ message: response.data.errormessage });
            return {};
        }

        //return getResponseHandler(response);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}


export const DeleteUserService= async (data) => {

    try {
        const rowData = await axios.post('/user/delete', data);
        const response = await rowData;

        if (response.data.status === 1) {
            notification.success({ message: response.data.message });
            return response.data.data;
        } else {
            notification.error({ message: response.data.errormessage });
            return {};
        }


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}


export const callApi= async (url , data) => {

    try {
        const rowData = await axios.post(url, data);
        const response = await rowData;
        console.log(response);

        if (response.data.status === 1) {
          
            return response.data.data;
        } else {
            notification.error({ message: response.data.errormessage });
            return false;
        }


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}
