import { Button, Form,Input,Switch, notification } from 'antd';
import {useState,useEffect} from 'react';
import CustomerObj from '../../utility/customerclass';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
const Add = () => {
    const componentSize='large';
    const [userData,setuserData]=useState({});
    const {id} =useParams();    
    const [form] = Form.useForm();
    useEffect(()=>{
    

    },[])
    const onFinish = async (values) => {       
      try{
       const customerinfo= await CustomerObj.AddCustomer(values);
       //console.log(customerinfo);
       if(Object.keys(customerinfo).length > 0){
        form.resetFields();
       }
      }catch(err){
       notification.error({message:err.message})   

      }
      

       /* Service.Loginservice.getLogin(values).then((result)=>{
         if(!result.status){
            notification.error({
              message:result.errormessage
            })
         }else{
            AuthClass.setUserSession(result.data.token);
            notification.success({
              message:result.message
            });            
            navigate('/admin');
            

         }

        }).catch((err)=>console.log(err));*/


        
};

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const handelChange=(e)=>{
  console.log(e.target.value);
  userData[e.target.name]=e.target.value;
  setuserData(userData);
   // console.log(e.target.name);



}

    return (
        <>
         <div className="card mb-4">
                <div className="card-header">User Add</div>
                <div className="card-body">
               <Form
                form={form}
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      
      
      size={componentSize}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
     // initialValues={userData}
    >
     
      <Form.Item label="First Name" name="first_name" rules={[
          {
            required: true,
            message: 'Please input First Name!',
          },
        ]}>
        <Input  onChange={(e)=>handelChange(e)}  />
      </Form.Item>
      <Form.Item label="Last Name"  name="last_name"
        rules={[
          {
            required: true,
            message: 'Please input Last Name!',
          },
        ]}
      >
        <Input   onChange={(e)=>handelChange(e)} />
      </Form.Item>
      <Form.Item label="Email"  name="email" 
         rules={[
          {
            required: true,
            message: 'Please input Email!',
          },
          {
            type: 'email',
            message: 'Please input Valid Email!',
          },
        ]}
      >
        <Input  onChange={(e)=>handelChange(e)}/>
      </Form.Item>
      <Form.Item label="Mobile" name="mobile"
        rules={[
          {
            required: true,
            message: 'Please input Mobile!',
          },
        ]}
      >
        <Input  onChange={(e)=>handelChange(e)}  />
      </Form.Item>
      <Form.Item label="Password" name="password"
         rules={[
          {
            required: true,
            message: 'Please input Password!',
          },
        ]}
      >
        <Input.Password  onChange={(e)=>handelChange(e)}  />
      </Form.Item>

      <Form.Item label="Status" name="status">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked={(userData.status)?true:false}
      onChange={(e)=>handelChange(e)}
      
    />
      </Form.Item>
     
      
      
      <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-8"> <Button type="primary" htmlType="submit" className="login-form-button"> Save </Button></div>
        
      </div>
     
      
    </Form>

                </div>
            </div>
        </>)
}

export default Add;