import { useEffect, useState } from 'react';
import { Button, Pagination, Input, AutoComplete, notification, Modal, Form,Select } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import customerObj from '../../utility/customerclass';
import reportObj from '../../utility/reportclass';
import { useNavigate, Link } from "react-router-dom";

const KV11List = () => {

    const [list, setlist] = useState([]);
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [divsionlist, setdivsionlist] = useState([]);
    const [sectionlist, setsectionlist] = useState([]);
    const [kv33list, setkv33listlist] = useState([]);

    
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        async function getData() {
            const listdata = await customerObj.getkvfeeder11({ include: true });

            setlist(listdata);
        }

        getData();

        async function getDivision() {
            const divisionData = await reportObj.getDivision({});

            setdivsionlist(divisionData);
        }

        getDivision();


    }, []);

    const changeDivision = async (e) => {

        console.log(e);

        const listdata = await customerObj.getSection({ division_id: e });

        setsectionlist(listdata);


    }

    const changeSection = async (e) => {

        console.log(e);

        const listdata = await customerObj.getkvfeeder33({ section_id: e });

        setkv33listlist(listdata);


    }

    

    const handleDelete = async (e, id, key) => {
        console.log(id);
        try {
            const customerinfo = await reportObj.deleteCollectionLayer({ id: id });
            list.splice(key, 1);
            let newarr = list;
            setlist(newarr);

        } catch (err) {
            notification.error({ message: err.message });

        }

    }

    const onFinish = async (values) => {
        let res = await reportObj.saveKV11(values);
        if (res?.status == 1) {
            console.log('Saved');
            list.push(res?.data);
            setIsModalOpen(false);
            notification.success({ message: "Saved Successfully" });

        } else {
            notification.error({ message: res?.message });
        }
        console.log(res);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>

            <div className="card mb-4">
                <div className="card-header">11 KV List
                <div className="card-header-right"><Button type="primary" onClick={showModal}>Add New</Button></div>
                </div>
                <div className="card-body">

                    <div className="tab-content rounded-bottom">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th >#</th>

                                    <th>Feeder 33 KV</th>
                                    <th>Feeder 11 KV</th>
                                    <th>Code</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((item, itemKey) => {
                                        return (
                                            <tr key={item.id}>
                                                <td >{(itemKey + 1)}</td>
                                                <td >{item.kvfeeder33?.feeder_name}</td>
                                                <td >{item.feeder_name}</td>
                                                <td >{item.feeder_code}</td>
                                                <td ><Button type="primary" size="small"><Link to={`${item.id}`}>Edit</Link></Button>

                                                    {/*  <Popconfirm placement="bottom" title="Do You Want To Delete ?" onConfirm={(e) => handleDelete(e, item.id, itemKey)} okText="Yes" cancelText="No">
                                                        <Button icon={<DeleteOutlined />}>Delete</Button>
                                        </Popconfirm>*/}
                                                </td>

                                            </tr>

                                        )

                                    })
                                }

                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <Modal title="KV11 Save " visible={isModalOpen} onOk={form.submit} okText="Save" onCancel={handleCancel}>
                <Form
                    form={form}
                    style={{
                        maxWidth: 600,
                    }}

                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item label="Division"
                        name="division_id"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Division!',
                            },
                        ]}
                    >
                        <Select placeholder="Division" onChange={e => changeDivision(e)} >
                            <option value="">Select</option>
                            {divsionlist.map(item => {

                                return (<option value={item.id}>{item.division_name}</option>)
                            })}
                        </Select>
                    </Form.Item>


                    <Form.Item label="Section"
                        name="section_id"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Section!',
                            },
                        ]}
                    >
                        <Select placeholder="Section"  onChange={e => changeSection(e)}>
                            <option value="">Select</option>
                            {sectionlist.map(item => {

                                return (<option value={item.id}>{item.section_name}</option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Feeder33"
                        name="kvfeeder33_id"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Feeder33!',
                            },
                        ]}
                    >
                        <Select placeholder="Feeder33" >
                            <option value="">Select</option>
                            {kv33list.map(item => {

                                return (<option value={item.id}>{item.feeder_name}</option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Feeder11 Name"
                        name="feeder_name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Feeder11 Name!',
                            },
                        ]}
                    >
                        <Input placeholder="Feeder11 Name" />
                    </Form.Item>
                    <Form.Item label="Feeder11 Code"
                        name="feeder_code"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Feeder11 Code!',
                            },
                        ]}
                    >
                        <Input placeholder="Feeder11 Code" />
                    </Form.Item>

                </Form>
            </Modal>
        </>
    )

}

export default KV11List;