import { useEffect, useState } from 'react';
import { Button, Pagination, Input, AutoComplete, notification, Modal,Form } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import reportObj from '../../utility/reportclass';
import { useNavigate, Link } from "react-router-dom";

const DivisionList = () => {

    const [list, setlist] = useState([]);
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        async function getData() {
            const divisionlist = await reportObj.getDivision({});

            setlist(divisionlist);
        }

        getData();


    }, []);


    const handleDelete = async (e, id, key) => {
        console.log(id);
        try {
            const customerinfo = await reportObj.deleteCollectionLayer({ id: id });
            list.splice(key, 1);
            let newarr = list;
            setlist(newarr);

        } catch (err) {
            notification.error({ message: err.message });

        }

    }

    const onFinish = async (values) => {
        console.log('Success:', values);
        let res=  await reportObj.saveDivision(values);
        if(res?.status==1){
            console.log('Saved');
            list.push(res?.data);
            setIsModalOpen(false);

        }else{
            notification.error({message:res?.message});
        }
        console.log(res);
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };


    return (
        <>

            <div className="card mb-4">
                <div className="card-header">
                    Division List
                    <div className="card-header-right"><Button type="primary" onClick={showModal}>Add New</Button></div>
                </div>
                <div className="card-body">

                    <div className="tab-content rounded-bottom">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th>Division</th>
                                    <th>Code</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((item, itemKey) => {
                                        return (
                                            <tr key={item.id}>
                                                <td >{(itemKey + 1)}</td>

                                                <td >{item.division_name}</td>
                                                <td >{item.division_code}</td>
                                                <td ><Button type="primary" size="small"><Link to={`${item.id}`}>Edit</Link></Button>

                                                    {/*  <Popconfirm placement="bottom" title="Do You Want To Delete ?" onConfirm={(e) => handleDelete(e, item.id, itemKey)} okText="Yes" cancelText="No">
                                                        <Button icon={<DeleteOutlined />}>Delete</Button>
                                        </Popconfirm>*/}
                                                </td>

                                            </tr>

                                        )

                                    })
                                }

                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <Modal title="Division Save " visible={isModalOpen} onOk={form.submit} okText="Save" onCancel={handleCancel}>
            <Form     
      form={form}
      style={{
        maxWidth: 600,
      }}

      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
         <Form.Item label="Division Name"
          name="division_name"
          rules={[
            {
              required: true,
              message: 'Please input Division Name!',
            },
          ]}
         >
        <Input placeholder="Division Name" />
      </Form.Item>
      <Form.Item label="Division Code"
        name="division_code"
        rules={[
          {
            required: true,
            message: 'Please input Division Code!',
          },
        ]}
      >
        <Input placeholder="Division Code" />
      </Form.Item>
     
    </Form>
            </Modal>
        </>
    )

}

export default DivisionList;