let development={
//url:'http://localhost:3002/',
url:'http://sticker.geomaticxmap.in:3002/',
//sessionkey:'_surveyDashboard'
imageurl:"https://9yltpyjig6.execute-api.ap-south-1.amazonaws.com/survey/saveimage"
}

let production={
    url:'',
    sessionkey:'_survey2Dashboard'    
}
let config=(process.env.REACT_APP_ENVIRONMENT==='production')?production:development;

export default config;
