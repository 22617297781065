import { useEffect, useState } from 'react';
import { Button, Pagination, Input , AutoComplete,notification,Modal,Form,Select } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import reportObj from '../../utility/reportclass';
import customerObj from '../../utility/customerclass';
import { useNavigate,Link } from "react-router-dom";

const SectionList = () => {

    const [list, setlist] = useState([]);
    const [divsionlist, setdivsionlist] = useState([]);
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
      async function getData (){
        const listdata=await customerObj.getSection({});
      
        setlist(listdata);
       }
      
       getData();
       
       async function getDivision() {
        const divisionData = await reportObj.getDivision({});

        setdivsionlist(divisionData);
    }

    getDivision();


    }, []);


    const handleDelete = async (e, id, key) => {
        console.log(id);
       try{
            const customerinfo= await reportObj.deleteCollectionLayer({id:id});
            list.splice(key, 1);
            let newarr = list;
            setlist(newarr);           

        }catch(err){
            notification.error({message:err.message});

        }
       
    }

    const onFinish = async(values) => {
        console.log('Success:', values);
        let res=  await reportObj.saveSection(values);
        if(res?.status==1){
            console.log('Saved');
            list.push(res?.data);
            setIsModalOpen(false);

        }else{
            notification.error({message:res?.message});
        }
        console.log(res);
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return (
        <>
          
            <div className="card mb-4">
                <div className="card-header">Section List
                <div className="card-header-right"><Button type="primary" onClick={showModal}>Add New</Button></div>
                </div>
                <div className="card-body">
                   
                    <div className="tab-content rounded-bottom">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th >#</th>                                   
                                    <th>Section</th>
                                    <th>Code</th>                                   
                                    <th>Action</th>
                                                                   
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((item, itemKey) => {
                                        return (
                                                 <tr key={item.id}>
                                                    <td >{(itemKey + 1)}</td>
                                                    
                                                    <td >{item.section_name}</td>
                                                    <td >{item.section_code}</td>
                                                    <td ><Button type="primary" size="small"><Link to={`${item.id}`}>Edit</Link></Button>

                                                  {/*  <Popconfirm placement="bottom" title="Do You Want To Delete ?" onConfirm={(e) => handleDelete(e, item.id, itemKey)} okText="Yes" cancelText="No">
                                                        <Button icon={<DeleteOutlined />}>Delete</Button>
                                        </Popconfirm>*/}
</td>                                                   
                                               
                                                 </tr>

                                        )

                                    })
                                }

                            </tbody>
                        </table>
                        
                    </div>
                </div>
            </div>

            <Modal title="Section Save " visible={isModalOpen} onOk={form.submit} okText="Save" onCancel={handleCancel}>
            <Form     
      form={form}
      style={{
        maxWidth: 600,
      }}

      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >

<Form.Item label="Division"
          name="division_id"
          rules={[
            {
              required: true,
              message: 'Please input Division!',
            },
          ]}
         >
        <Select placeholder="Division" >
            <option value="">Select</option>
            {divsionlist.map(item=>{

                return ( <option value={item.id}>{item.division_name}</option>)
            })}
        </Select>
      </Form.Item>
         <Form.Item label="Section Name"
          name="section_name"
          rules={[
            {
              required: true,
              message: 'Please input Section Name!',
            },
          ]}
         >
        <Input placeholder="Section Name" />
      </Form.Item>
      <Form.Item label="Section Code"
        name="section_code"
        rules={[
          {
            required: true,
            message: 'Please input Section Code!',
          },
        ]}
      >
        <Input placeholder="Section Code" />
      </Form.Item>
     
    </Form>
            </Modal>
        </>
    )

}

export default SectionList;