import {Routes,Route} from 'react-router-dom';

import Login from './layout/Login';
import DashboardLayout from './layout/DashboardLayout';
import MapLayout from './layout/MapLayout';
import Error from './layout/Error';

import Protected from './protected.route'

import DashboardPage from './components/Dashboard/index';

import UserList from './components/User/index.jsx';
import UserAdd from './components/User/add.jsx';
import UserEdit from './components/User/edit.jsx';

import WorkOrderList from './components/Setting/work.order.list';
import WorkOrderLoadList from './components/Setting/work.order.loadlist';

import CollectionLayer from './components/Setting/CollectionLayer';
import CollectionForm from './components/Setting/CollectionLayerForm';
import DivisionList from './components/Setting/DivisionList';
import SectionList from './components/Setting/SectionList';
import KV33List from './components/Setting/KV33List';
import KV11List from './components/Setting/KV11List';
import TransformerList from './components/Setting/TransformerList';

import ReportList from './components/Report/index.jsx';
import MapReport from './components/Report/MapReport.jsx';

import ReportView from './components/Report/View.jsx';
import TaskList from './components/Report/Tasklist.jsx';
import TaskAdd from './components/Report/TaskAdd';
import Reassign from './components/Report/Reassign';
import UploadData from './components/Report/UploadData';
import UserWiseReport from './components/Report/UserWiseReport'
import UserWiseReportOld from './components/Report/UserWiseReportOld'

import { motion } from 'framer-motion';
const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.5
  }; 

  const animationConfiguration = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
};
export default function Routing(){

    return (
        <motion.div
            variants={animationConfiguration}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 3 }}
        >
    <Routes>
      <Route path="/" element={<Login/>}></Route>
      
      <Route path="/admin" element={<Protected><DashboardLayout/></Protected>}>
          <Route index element={<DashboardPage />}></Route>
          <Route  path="/admin/user/list" element={<UserList />}></Route>
          <Route  path="/admin/user/edit/:id" element={<UserEdit />}></Route>
          {<Route  path="/admin/user/add" element={<UserAdd />}></Route>}
         <Route  path="/admin/workorder/loadlist" element={<WorkOrderLoadList />}></Route>
         <Route  path="/admin/workorder/list" element={<WorkOrderList />}></Route>
         <Route  path="/admin/report/list" element={<ReportList />}></Route>
         <Route  path="/admin/report/map" element={<MapReport />}></Route>         
         <Route  path="/admin/report/list/:id" element={<ReportView />}></Route>
         <Route  path="/admin/task/list" element={<TaskList />}></Route>
         <Route  path="/admin/task/add" element={<TaskAdd />}></Route>
         <Route  path="/admin/collection/list" element={<CollectionLayer />}></Route>
         <Route  path="/admin/collection/form/:id" element={<CollectionForm />}></Route>
         <Route  path="/admin/division/list" element={<DivisionList />}></Route>
         <Route  path="/admin/section/list" element={<SectionList />}></Route>
         <Route  path="/admin/kvfeeder33/list" element={<KV33List />}></Route>
         <Route  path="/admin/kvfeeder11/list" element={<KV11List />}></Route>
         <Route  path="/admin/transformer/list" element={<TransformerList />}></Route>
         <Route  path="/admin/reassign" element={<Reassign />}></Route>
         <Route  path="/admin/uploadgeojson" element={<UploadData />}></Route>
         <Route  path="/admin/userwisereport" element={<UserWiseReport />}></Route>
         <Route  path="/admin/userwisereportold" element={<UserWiseReportOld />}></Route>
         
         
         
      </Route>
    <Route path="/admin/map/report" element={<Protected><MapLayout/></Protected>}>
       <Route index element={<MapReport />}></Route>
    </Route>
     
      <Route path="*" element={<Error/>}></Route>
   </Routes>
    </motion.div>
    )

}