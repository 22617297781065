import React, { useEffect,useState } from 'react'
import common from '../../utility/commonclass';
import ReportObj from '../../utility/reportclass';
import { Button, Pagination, Popconfirm, notification,message, Modal, Empty, AutoComplete, Input, DatePicker, Drawer,Select,Spin,Upload } from 'antd';
import { useNavigate, Link } from "react-router-dom";
import { CheckOutlined, CloseOutlined,AimOutlined,UploadOutlined } from '@ant-design/icons';
import Config from '../../config/index';
import CustomerObj from '../../utility/customerclass';
function LayerForm(props) {
    const visible=props?.visible;
    const ext_id=props?.ext_id;
    const pointLocation=props?.location;
    const selectedPoles=props?.selectedPoles;
    console.log(props);
    const [formlist,setFormFields]=useState([]);
    const [formValue, setformValue] = useState({});
    const [formError, setformError] = useState({});
    const [sections, setsection] = useState([]);
    const [kv11s, setkv11] = useState({list:{},options:[]});
    const [dts, setdts] = useState({list:{},options:[]});

    const [currentImageField, setcurrentImageField] = useState("");

    const [NorminalVoltages, setNorminalVoltages] = useState(['240V','415V','11KV']);
    const [circuits, setcircuit] = useState(['A','B','C','D','E']);
    const [staticFields, setstaticFields] = useState({});
    
    
   
    
    useEffect(()=>{
        getFormList();
        getSectionList();

    },[]);

    const getSectionList=async()=>{

        const sectionlist = await CustomerObj.getSection({});
        console.log(sectionlist);
        setsection(sectionlist);


    }

    async function getkvfeeder11(section_id) {
        const kvfeeder11list = await CustomerObj.getkvfeeder11({sectionid:section_id});
        console.log(kvfeeder11list);
        //setkvfeeder11Data(kvfeeder11list);
        const kvfeeder11listfilter = kvfeeder11list.reduce((pre,item) => {
            pre[item?.id]=item;
          return pre;
    
        },{})
        console.log(kvfeeder11listfilter);
        setkv11({list:kvfeeder11listfilter,options:kvfeeder11list});
      }

      async function getTransformer(kvfeeder11_id) {
        const transformerlist = await CustomerObj.getTransformer({kvfeeder11_id:kvfeeder11_id});
        

        const transformerlistfilter = transformerlist.reduce((pre,item) => {
            pre[item?.id]=item;
          return pre;
    
        },{})     
       
        setdts({list:transformerlistfilter,options:transformerlist});
      }

    const getFormList=async()=>{

        let formlist = await ReportObj.getCollectionLayerForm({ ext_id: ext_id });
        setFormFields(formlist);
    }

    const setStaticvalue =async(e)=>{
        console.log(e?.target?.name);
        console.log(e?.target?.value);      
        setstaticFields((pre)=>({...pre,[e?.target?.name]:e?.target?.value}));
        if(e?.target?.name=='section'){
            getkvfeeder11(e?.target?.value);

        }

        if(e?.target?.name=='kv11'){
            getTransformer(e?.target?.value);

        }
        console.log(staticFields);
    }

    const setvaue =(e,key)=>{
        let item=formlist[key];
      
      //  formValue[item.field_name]?.field_value
      if(typeof( formValue[item.field_name])=='undefined'){
        formValue[item.field_name]={}
      }
      formValue[item.field_name]=e.target.value;
      //console.log(formValue[item.field_name].field_value);
      setformValue((pre)=>({...pre,[item.field_name]:formValue[item.field_name]}));
       // console.log(formValue[item.field_name]?.field_value);
    }

    const validateform=async()=>{
        let error={};
        console.log(formValue);
        for(let value of formlist){
           
            if(value?.mandatory==1 && common.isEmpty(formValue[value?.field_name])==true){
                //if(value?.data_type.toLowerCase() !='image'){
                    error[value?.field_name]="Please Fill "+value?.field_label;
                //}
                
            }

        }
        setformError(error);
        return error;

    }


    const SaveData=async()=>{
        if(common.isEmpty(staticFields?.kv11)==true){
            notification.error({key:"error",message:"Please Select 11 KV Fields"});
            return false;
        }
        
        let error=   await validateform();

        if(Object.keys(error).length > 0){
            notification.error({key:"error",message:"Please Fill All Required Fields"});
            return false;
        }

        let formdata={};
        let kvfeeder11_id=staticFields?.kv11;
        let kv11detail= kv11s?.list[kvfeeder11_id];
        let section_id=kv11detail?.section_id;
        let kvfeeder33_id=kv11detail?.kvfeeder33_id;;
       
        let dt_id=common.isEmpty(staticFields?.dt)==false?staticFields?.dt:0;
        let code="";
        let workorder_id="";
        let coordinates=[];
        let paint_code="";
        let element_type="";
        let composite_type="";
        let no_branch="";
        let cut_point="";
        let nominal_voltage=common.isEmpty(staticFields?.norminal)==false?staticFields?.norminal:"";
        let no_of_hv=0;
        let no_of_mv=0;
        let no_of_lv=0;
        let jumper=0;
        let dt_circuit=common.isEmpty(staticFields?.circuit)==false?staticFields?.circuit:"";
        let startpoleid="0";
        let endpoleid="0";
        let startpoleType="";
        let endpoleType="";

        if(ext_id==2){
            coordinates.push({latitude:pointLocation?.lat,longitude:pointLocation?.lng});
        }

        if(ext_id==17 || ext_id==3){
            coordinates.push({latitude:selectedPoles[0]?.location?.latitude,longitude:selectedPoles[0]?.location?.longitude});
            coordinates.push({latitude:selectedPoles[1]?.location?.latitude,longitude:selectedPoles[1]?.location?.longitude});
            startpoleid=selectedPoles[0]?.id;
            endpoleid=selectedPoles[1]?.id;
            startpoleType=selectedPoles[0]?.type;
            endpoleType=selectedPoles[1]?.type;


        }

        if(ext_id==14){
            coordinates.push({latitude:selectedPoles[0]?.location?.latitude,longitude:selectedPoles[0]?.location?.longitude});
            startpoleid=selectedPoles[0]?.id;
            startpoleType=selectedPoles[0]?.type;
            endpoleType="DT";
        }


        let SurveyItemdata=[];
        for(let value of formlist){
                let tmp={}
                tmp['field_id']=value?.id;
                tmp['field_name']=value?.field_name;
                tmp['field_value']=formValue[value?.field_name];
                tmp['field_label']=value?.field_label;
                tmp['shp_field_name']=value?.shp_field_name;
                tmp['s3']="";
                if(value?.data_type.toLowerCase()=='image'){
                    tmp['s3']=formValue[value?.field_name];
                }

                if(ext_id==2){
                    if(value?.field_name=='tp_pole_paint_code'){
                        paint_code=formValue[value?.field_name];
                    }
                    if(value?.field_name=='Comp_Typ'){
                        composite_type=formValue[value?.field_name];
                    }

                    if(value?.field_name=='No_Brnch'){
                        no_branch=formValue[value?.field_name];
                    }
                    if(value?.field_name=='Cut_Point'){
                        cut_point=formValue[value?.field_name];
                    }

                    if(value?.field_name=='tp_no_of_hv_circuits'){
                        no_of_hv=formValue[value?.field_name];
                    }

                    if(value?.field_name=='tp_no_of_mv_circuits'){
                        no_of_mv=formValue[value?.field_name];
                    }

                    if(value?.field_name=='tp_no_of_lv_circuits'){
                        no_of_lv=formValue[value?.field_name];
                    }
                    if(value?.field_name=='Jmpr_Conct'){
                        jumper=formValue[value?.field_name];
                    }


                }

                SurveyItemdata.push(tmp);
                
                


           

        }


        
        let insertdata={}
        insertdata['user_id']=0;
        insertdata['project_id']=1;
        insertdata['section_id'] = section_id;
        insertdata['kvfeeder33_id']=kvfeeder33_id;
        insertdata['kvfeeder11_id']=kvfeeder11_id;
        insertdata['dt_id']=dt_id;
        insertdata['dt_circuit']=dt_circuit;
        insertdata['mobile_record_number']=code;
        insertdata['record_number']='';
        insertdata['workorder_id']=workorder_id;
        insertdata['item_type']="Feature";
        insertdata['ext_id']=ext_id;
        insertdata['geometry_type']="Point";
        insertdata['coordinates']=JSON.stringify(coordinates);
        insertdata['status']=1;         
        insertdata['app_save_date']= "";
        insertdata['app_info']="";
        insertdata['app_item_id']=0;;
        insertdata['work_id']=0;  
        insertdata['paint_code']=paint_code;
        insertdata['element_type']=element_type;  
        insertdata['composite_type']=composite_type;
        insertdata['no_branch']=no_branch;  
        insertdata['cut_point']=cut_point;  
        insertdata['nominal_voltage']=nominal_voltage;  
        insertdata['no_of_hv']=no_of_hv;  
        insertdata['no_of_mv']=no_of_mv; 
        insertdata['no_of_lv']=no_of_lv; 
        insertdata['jumper']=jumper;  
        insertdata['add_type']='dashboard';                  
        insertdata['isShare']=1;
        insertdata['startpoleid']=startpoleid;
        insertdata['endpoleid']=endpoleid;
        insertdata['startpoleType']=startpoleType;
        insertdata['endpoleType']=endpoleType;
        insertdata['rowdata']="";
        insertdata['formdata']=SurveyItemdata;

        console.log(insertdata);

       let responce=    await CustomerObj.saveSurvey(insertdata);
        console.log('savedata');
        console.log(responce);
        notification.success({key:'save',message:"Survey has been saved successfully"});
        props.savelayer(responce);

    }


    const onuploadChange=async(info)=>{

        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          console.log("done");
          console.log(info?.file?.response?.data[0]?.location);
          setformValue((pre)=>({...pre,[currentImageField]:info?.file?.response?.data[0]?.location}));
          //setgeofile(info?.file?.response?.data?.filename);
        //  message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
    
      }
    
    
      const beforeUpload = (file,type) => {
        //console.log("filetype");
      // console.log(file);
       //console.log(type);       
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        setcurrentImageField(type);
        return  isLt2M;
      };

   

return (<>
            <Drawer
              width={"50%"}

                title="Form"
                placement="right"
                closable={false}
              //  onClose={(e) => onClose()}
                getContainer={false}
                visible={visible}
                key="drawerlayer"
                forceRender={true}
            >

<div className="card mb-12">
                      
                        <div className="card-body">
                            <div className="container">
                            
                            <div class="form-group row">
                                            <label for="staticEmail" class="col-sm-4 col-form-label">Section</label>
                                            <div class="col-sm-5">
                                                <select name={"section"} className="form-control" onChange={(e)=>{setStaticvalue(e)}}  >
                                                   <option value="">Select</option>
                                                    {sections.map((optionitem) => {
                                                        let selected = "";                                                       
                                                        return <option value={optionitem?.id} selected={selected}>{optionitem?.section_name}</option>
                                                    })}
                                                </select>
                                               

                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="staticEmail" class="col-sm-4 col-form-label">11 KV</label>
                                            <div class="col-sm-5">
                                                <select name={"kv11"} className="form-control" onChange={(e)=>{setStaticvalue(e)}}  >
                                                   <option value="">Select</option>
                                                    {kv11s?.options.map((optionitem,optionkey) => {
                                                        let selected = "";                                                       
                                                        return <option value={optionitem?.id} selected={selected}>{optionitem?.feeder_name}</option>
                                                    })}
                                                </select>
                                               

                                            </div>
                                        </div>


                                      {
                                      ext_id !=14 &&  <div class="form-group row">
                                            <label for="staticEmail" class="col-sm-4 col-form-label">Norminal Voltage</label>
                                            <div class="col-sm-5">
                                                <select name={"norminal"} className="form-control" onChange={(e)=>{setStaticvalue(e)}}  >
                                                   <option value="">Select</option>
                                                    {NorminalVoltages.map((optionitem) => {
                                                        let selected = "";                                                       
                                                        return <option value={optionitem} selected={selected}>{optionitem}</option>
                                                    })}
                                                </select>
                                               

                                            </div>
                                        </div>
                                    }

                                    {
                                      ( staticFields?.norminal =='240V' || staticFields?.norminal =='415V') && <div class="form-group row">
                                      <label for="staticEmail" class="col-sm-4 col-form-label">DT</label>
                                      <div class="col-sm-5">
                                          <select name={"dt"} className="form-control" onChange={(e)=>{setStaticvalue(e)}}  >
                                             <option value="">Select</option>
                                              {dts?.options.map((optionitem) => {
                                                  let selected = "";                                                       
                                                  return <option value={optionitem?.id} selected={selected}>{optionitem?.transformer_code}</option>
                                              })}
                                          </select>
                                         

                                      </div>
                                    </div>

                                    }

                                    { common.isEmpty(staticFields?.dt)==false && <div class="form-group row">
                                      <label for="staticEmail" class="col-sm-4 col-form-label">DT Circuit</label>
                                      <div class="col-sm-5">
                                          <select name={"circuit"} className="form-control" onChange={(e)=>{setStaticvalue(e)}}  >
                                             <option value="">Select</option>
                                              {circuits.map((optionitem) => {
                                                  let selected = "";                                                       
                                                  return <option value={optionitem} selected={selected}>{optionitem}</option>
                                              })}
                                          </select>
                                         

                                      </div>
                                    </div>

                                    }
    
                                  


                       
                                {formlist.map((item, itemkey) => {

                                    if (item.data_type == 'ENUM') {
                                        let options = item.field_values.split(",");

                                        return (<div class="form-group row">
                                            <label for="staticEmail" class="col-sm-4 col-form-label">{item.field_label}</label>
                                            <div class="col-sm-5">
                                                <select name={item.field_name} className="form-control" onChange={(e)=>setvaue(e,itemkey)}  >
                                                   <option value="">Select</option>
                                                    {options.map((optionitem) => {
                                                        let selected = "";                                                       
                                                        return <option value={optionitem} selected={selected}>{optionitem}</option>
                                                    })}
                                                </select>
                                                {common.isEmpty(formError[item.field_name])==false && <div className={"form-error"}>{formError[item.field_name]}</div>}

                                            </div>
                                        </div>)


                                    } else if (item.data_type == 'Image') {
                                        {return (<div class="form-group row">
                                            <label for="staticEmail" class="col-sm-4 col-form-label">{item.field_label}</label>
                                            <div class="col-sm-5">
                                                
                                               <Upload multiple={false} beforeUpload={(e)=>beforeUpload(e,item?.field_name)} maxCount={1} name="geofile" action={Config?.url+'image/saveimage'} onChange={onuploadChange}>
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  </Upload>

                                               {common.isEmpty(formError[item.field_name])==false && <div className={"form-error"}>{formError[item.field_name]}</div>}
                                            </div>
                                    </div>)}
                                    return (<></>)
                                    } else {
                                        return (<div class="form-group row">
                                            <label for="staticEmail" class="col-sm-4 col-form-label">{item.field_label}</label>
                                            <div class="col-sm-5">
                                                <input type="text" name={item.field_name} className="form-control" value={formValue[item.field_name]?.field_value} onChange={(e)=>setvaue(e,itemkey)}></input>
                                                {common.isEmpty(formError[item.field_name])==false && <div className={"form-error"}>{formError[item.field_name]}</div>}
                                            </div>
                                        </div>)
                                    }


                                })}
                            </div>
                            <div class="form-group row"><label for="staticEmail" class="col-sm-4 col-form-label"></label>
                                <div class="col-sm-2">
                                <Link className="btn btn-primary" to="" onClick={(e)=>SaveData()}>Save</Link>
                               </div><div class="col-sm-2">
                                <Link className="btn btn-danger" to="" onClick={(e)=>props?.cancel()}>Cancel</Link>
                                    </div></div>

                        </div>
                    </div>

            </Drawer>
        
 </>)
     
}

export default React.memo(LayerForm)