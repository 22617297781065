import moment from 'moment';
import { callApi } from '../services/report.service';

class Report {
    static myInstance = null;
    constructor() {


    }

    static getInstance() {
        if (Report.myInstance == null) {
            Report.myInstance = new Report();
        }

        return this.myInstance;
    }

    async getList(data = {}) {
        try {
            const listinfo = await callApi('/report/collectionlist', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }

    async getListItem(data = {}) {
        try {
            const listinfo = await callApi('/report/collectionItemlist', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }


    async getCollectionDetail(data = {}) {
        try {
            const listinfo = await callApi('/report/collectiondetail', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }



    async getTaskList(data = {}) {
        try {
            const listinfo = await callApi('/report/getassignwork', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }


    async getCollectionLayer(data = {}) {
        try {
            const listinfo = await callApi('/collection/getlayers', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }

    async deleteCollectionLayer(data = {}) {
        try {
            const listinfo = await callApi('/collection/deletelayers', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }

    async deleteRecord(data = {}) {
        try {
            const listinfo = await callApi('/collection/deleteRecord', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }

    


    async getCollectionLayerForm(data = {}) {
        try {
            const listinfo = await callApi('/collection/formData', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }

    async updateFormData(data = {}) {
        try {
            const listinfo = await callApi('/collection/updateFormData', data,true);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }

    async getImageFields(data = {}) {
        try {
            const listinfo = await callApi('/collection/getImageFields', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }


    async getCollectionLayer(data = {}) {
        try {
            const listinfo = await callApi('/collection/getlayers', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }


    async getCollectionLayerDetail(data = {}) {
        try {
            const listinfo = await callApi('/collection/layerdetail', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }

    async downloadReport(data = {}) {
        try {
            const listinfo = await callApi('/collection/downloadCollection', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }
    async SaveSurveyFormData(data = {}) {
        try {
            const listinfo = await callApi('/collection/updatesurveyformdata', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }

    }


    async getTransformerPole(data={}){
        try {
            const listinfo = await callApi('/collection/getTransformerPole', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }
    }


    async getTransformerPoleList(data={}){
        try {
            const listinfo = await callApi('/collection/getTransformerPoleList', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }
    }


    async saveTransformerpole(data={}){
        try {
            const listinfo = await callApi('/collection/saveTransformerpole', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }
    }



    async getDivision(data={}){
        try {
            const listinfo = await callApi('/collection/getDivision', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }
    } 

    async saveDivision(data={}){
        try {
            const listinfo = await callApi('/collection/saveDivision', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }
    } 

    async saveSection(data={}){
        try {
            const listinfo = await callApi('/collection/saveSection', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }
    } 
    async saveKV33(data={}){
        try {
            const listinfo = await callApi('/collection/saveKV33', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }
    } 

    async saveKV11(data={}){
        try {
            const listinfo = await callApi('/collection/saveKV11', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }
    } 


    async ChangePointLocation(data={}){
        try {
            const listinfo = await callApi('/collection/changepointlocation', data);
            return listinfo;

        } catch (err) {
            return { status: 0, error: err };

        }
    }
    
    
    


    

    

    


    


    

}
let Instance = Report.getInstance();
export default Instance;