import { useEffect, useState } from 'react';
import { getUsers } from '../../services/user.server';
import CustomerObj from '../../utility/customerclass';
import { Button, Pagination, Popconfirm,notification } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import { useNavigate } from "react-router-dom";

const List = () => {
    const [customerList, setCustomerList] = useState({ totalrecord: 0, list: [] });
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 10 });
    const [filter, setFilter] = useState({});
    let navigate = useNavigate();
    useEffect(() => {
        console.log('call customer useEffect');
        getUsers({ page: paging.currentpage, limit: paging.itemlimit, filter: filter }).then(list => {
            setCustomerList(() => {
                return { totalrecord: list.count, list: list.rows };
            })
        });
    }, [paging]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setpage((pre) => {
            return { ...pre, currentpage: 1 }
        })
        getUsers({ page: paging.currentpage, limit: paging.itemlimit, filter: filter }).then(list => {
            setCustomerList(() => {
                return { totalrecord: list.count, list: list.rows };
            })

        });
    }
    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }

        })
    }
    const handlePageChange = (page) => {

        setpage((pre) => {
            return { ...pre, currentpage: page }
        })
    }
    const handleDelete = async (e, id, key) => {
        try{
            const customerinfo= await CustomerObj.deleteCustomer(id);
            customerList.list.splice(key, 1);
            let newarr = customerList.list;
            console.log(newarr);
            setCustomerList((preList) => {
                return { totalrecord: customerList.totalrecord - 1, list: customerList.list };
            })

        }catch(err){
            notification.error({message:err.message});

        }
       
    }

    const haldelEdit = (e, id) => {
        navigate('/admin/user/edit/' + id);

    }

    const haldelAssign =(e,id)=>{


    }



    return (
        <>
            <div class="callout callout-info bg-white">

                <form class="row row-cols-lg-auto g-3 align-items-center" onSubmit={(e) => { handleSubmit(e) }}>
                    <div class="col-12">
                        <input type="text" class="form-control" name="name" placeholder="Name" onChange={(e) => handleChange(e)} />
                    </div>

                    <div class="col-12">

                        <input type="text" class="form-control" name="email" placeholder="Email" onChange={(e) => handleChange(e)} />
                    </div>
                    <div class="col-12">

                        <input type="text" class="form-control" name="mobile" placeholder="Mobile" onChange={(e) => handleChange(e)} />
                    </div>

                    <div class="col-12">
                        <div class="form-check">
                            <select class="form-select" name="status" onChange={(e) => handleChange(e)}>
                                <option selected value="">Choose Status</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12">

                        <button className="btn btn-primary" >Search</button>
                    </div>
                </form>
            </div>
            <div className="card mb-4">
                <div className="card-header">User List</div>
                <div className="card-body">
                    <div className="tab-content rounded-bottom">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Status</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customerList.list.map((item, itemKey) => {
                                        return (
                                            <tr key={item.id}>
                                                <td >{((paging.currentpage - 1) * paging.itemlimit) + (itemKey + 1)}</td>
                                                <td >{item.first_name.concat(' ' + item.last_name)}</td>
                                                <td >{item.email}</td>
                                                <td>{item.mobile}</td>
                                                <td>{(item.status === 1) ? 'Active' : 'Inactive'}</td>
                                                <td >{common.dateFormat(item.createdAt)}</td>
                                                <td >                                               
                                                <Button icon={<EditOutlined />} onClick={(e) => haldelEdit(e, item.id)}>Edit</Button> &nbsp;
                                                <Popconfirm placement="bottom" title="Do You Want To Delete ?" onConfirm={(e) => handleDelete(e, item.id, itemKey)} okText="Yes" cancelText="No">
                                                        <Button icon={<DeleteOutlined />}>Delete</Button>
                                                    </Popconfirm>

                                                </td>
                                            </tr>

                                        )

                                    })
                                }

                            </tbody>
                        </table>
                        <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={customerList.totalrecord} onChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </>
    )

}

export default List;