import axios from '../config/axios';
import { notification } from 'antd';

function getResponseHandler(response,message) {

    if (response.data.status === 1) {
            if(message==true){
                return response.data;
            }else{
                return response.data.data;
            }
        
    } else {
        notification.error({ message: response.data.errormessage });
        return [];
    }

}

export const callApi =async (url,data={},message=false)=>{
    try {
        const rowData = await axios.post(url, data);
        const response = await rowData;
        return getResponseHandler(response,message);

    } catch (error) {
        console.error(error);
        notification.error({ message: error.message
     });


}
}

