import { useEffect, useState } from 'react';
import { getUsers } from '../../services/user.server';

import ReportObj from '../../utility/reportclass';
import CustomerObj from '../../utility/customerclass';
import { Button, Pagination, Popconfirm, notification } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import { useNavigate, useParams, Link } from "react-router-dom";
import { triggerFocus } from 'antd/lib/input/Input';


const View = () => {
    const [detail, setDetail] = useState({ collection: {}, url: "" });
    const [formlist, setformlist] = useState([]);
    const [formValue, setformValue] = useState({});
    const [errors, seterrors] = useState({});
    const [Transformerdetail, setTransformerdetail] = useState({});
    const [TransformerPoles, setTransformerPoles] = useState([]);
    const [selectedPole, setSelectedPole] = useState("");

    let navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        async function getdetail() {
            const repostdetail = await ReportObj.getCollectionDetail({ id: id });
            console.log(repostdetail);
            setDetail({ collection: repostdetail?.collection, url: repostdetail?.url });

            let formval = repostdetail?.collection?.surveyitems.reduce((items, current) => {
                items[current?.field_name] = current;
                return items;
            }, {})

            setformValue(formval);

            let formlist = await ReportObj.getCollectionLayerForm({ ext_id: repostdetail?.collection?.ext_id });
            console.log(formlist);
            setformlist(formlist);

            if (repostdetail?.collection?.ext_id == 14) {
                let TransformerPole = await ReportObj.getTransformerPole({ code: repostdetail?.collection?.mobile_record_number });
                setTransformerdetail(TransformerPole);
                if (common.isEmpty(TransformerPole?.ht_pole_code) == true) {
                    let TransformerPoleList = await ReportObj.getTransformerPoleList({ kvfeeder11_id: TransformerPole?.kvfeeder11_id, coordinates: repostdetail?.collection?.coordinates });
                    setTransformerPoles(TransformerPoleList);
                }


            }


        }

        getdetail();

    }, []);



    const ChangeHandel = async (e, key) => {


        let name = e.target.name;
        let value = e.target.value.trim();
        let tmp = {}
        if (common.isEmpty(formValue[name]) == false) {
            tmp = formValue[name];
            tmp['field_value'] = value;
            setformValue((pre) => ({ ...pre, tmp }))
        } else {
            tmp['survey_id'] = id;
            tmp['field_id'] = formlist[key]["id"];
            tmp['field_name'] = formlist[key]["field_name"];
            tmp['field_value'] = value;
            tmp['shp_field_name'] = formlist[key]["shp_field_name"];
            formValue[name] = tmp;
            setformValue(formValue)

        }



    }
    const validateForm = async () => {
        let error = {};
        for (let i in formlist) {
            if (formlist[i]['mandatory'] == 1 && common.isEmpty(formValue[formlist[i]['field_name']]['field_value']) == true) {
                error[formlist[i]['field_name']] = "Please Fill this Field";
            }

        }
        return error;
    }
    const saveFormValue = async () => {
        let error = await validateForm();
        seterrors(error);
        if (Object.keys(error).length > 0) {
            return true;
        }
        let data = await ReportObj.SaveSurveyFormData({ formdata: formValue, id: id });
        if (data == true) {

            notification.success({ message: "Save Successfully" });
        }

    }

    const saveTransformerpole =async (id)=>{
        console.log(id);
        console.log(selectedPole);
        if(common.isEmpty(selectedPole)==true){
            notification.error({message:"Please Select Pole"});
            return false;
        }
      
        if(selectedPole.includes("Tmp-")===true){
            notification.error({message:"This Pole have not generate Migration Id yet"});
            return false;
        }

        console.log("Saved");
        let data = await ReportObj.saveTransformerpole({ ht_pole_code:selectedPole, id: id });
       
     
            notification.error({message:"Pole has beed saved successfully"});
        
       

    }


    return (
        <>

            <div className="card mb-4">
                <div className="card-header">Collection Detail</div>
                {Object.keys(detail?.collection).length > 0 && <div className="card-body">
                    <div className="tab-content rounded-bottom">

                        <div className="card mb-12">
                            <div className="card-header">User Information</div>
                            <div className="card-body">

                                <div class="form-group row">
                                    <label for="staticEmail" class="col-sm-2 col-form-label">Name</label>
                                    <div class="col-sm-10">
                                        {`${detail?.collection?.customer?.first_name} ${detail?.collection?.customer?.last_name}`}
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
                                    <div class="col-sm-10">
                                        {`${detail?.collection?.customer?.email} `}
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-sm-2 col-form-label">Mobile</label>
                                    <div class="col-sm-10">
                                        {`${detail?.collection?.customer?.mobile} `}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <br />
                        <div className="card mb-12">
                            <div className="card-header">Location Information</div>
                            <div className="card-body">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-sm-2 col-form-label">coordinates</label>
                                    <div class="col-sm-10">
                                        {`${detail?.collection?.coordinates} `}
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="staticEmail" class="col-sm-2 col-form-label">Layer Name</label>
                                    <div class="col-sm-10">
                                        {`${detail?.collection?.objectextname?.name} `}
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="staticEmail" class="col-sm-2 col-form-label">Application Save Date</label>
                                    <div class="col-sm-10">
                                        {`${detail?.collection?.app_save_date} `}
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="staticEmail" class="col-sm-2 col-form-label">Code</label>
                                    <div class="col-sm-10">
                                        {`${detail?.collection?.mobile_record_number} `}
                                    </div>
                                </div>


                            </div>
                        </div>
                        {detail?.collection?.ext_id == 14 && <>
                            <br />
                            <div className="card mb-12">
                                <div className="card-header">Pole Detail</div>
                                <div className="card-body">
                                    <div className="container">
                                        <div class="form-group row">
                                            <label for="staticEmail" className="col-sm-2 col-form-label">Pole Code</label>
                                            {common.isEmpty(Transformerdetail.ht_pole_code) == true && <><div className="col-sm-5"><select className="form-control" onChange={(e)=>{setSelectedPole(e.target.value)}}>
                                                <option value="">Select Pole</option>
                                                {TransformerPoles.map((item) => {
                                                    let code=common.isEmpty(item['record_number'])==true?'Tmp-'+item['mobile_record_number']:item['record_number'];
                                                   return( <option value={code}>{code}</option>);
                                                })}
                                            </select>
                                          
                                            </div><div className="col-sm-2">  <button className="btn btn-primary" onClick={(e)=>saveTransformerpole(Transformerdetail?.id)}>Save</button></div></>}
                                            {common.isEmpty(Transformerdetail.ht_pole_code) == false && <div className="col-sm-5">{Transformerdetail.ht_pole_code}</div>}
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </>}

                        <br />
                        <div className="card mb-12">
                            <div className="card-header">Form Information</div>
                            <div className="card-body">
                                <div className="container">
                                    {formlist.map((item, itemkey) => {

                                        if (item.data_type == 'ENUM') {
                                            let options = item.field_values.split(",");

                                            return (<div class="form-group row">
                                                <label for="staticEmail" class="col-sm-2 col-form-label">{item.field_label}</label>
                                                <div class="col-sm-5">
                                                    <select name={item.field_name} className="form-control" onChange={(e) => { ChangeHandel(e, itemkey) }} >
                                                        {options.map((optionitem) => {
                                                            let selected = "";
                                                            if (formValue[item.field_name]?.field_value == optionitem) {
                                                                selected = "selected";
                                                            }
                                                            return <option value={optionitem} selected={selected}>{optionitem}</option>
                                                        })}
                                                    </select>
                                                    {common.isEmpty(errors[item.field_name]) == false && <span className={"error"}>{errors[item.field_name]}</span>}

                                                </div>
                                            </div>)


                                        } else if (item.data_type == 'Image') {
                                            return (<div class="form-group row">
                                                <label for="staticEmail" class="col-sm-2 col-form-label">{item.field_label}</label>
                                                <div class="col-sm-5">
                                                    <img src={`${detail?.url}${formValue[item.field_name]?.field_value}`}></img>
                                                </div>
                                            </div>)
                                        } else {
                                            return (<div class="form-group row">
                                                <label for="staticEmail" class="col-sm-2 col-form-label">{item.field_label}</label>
                                                <div class="col-sm-5">
                                                    <input type="text" name={item.field_name} className="form-control" onChange={(e) => { ChangeHandel(e, itemkey) }} value={formValue[item.field_name]?.field_value}></input>
                                                    {common.isEmpty(errors[item.field_name]) == false && <span className={"error"}>{errors[item.field_name]}</span>}
                                                </div>
                                            </div>)
                                        }


                                    })}
                                </div>
                                <div class="form-group row"><label for="staticEmail" class="col-sm-2 col-form-label"></label>
                                    <div class="col-sm-5"><Link className="btn btn-primary" to="" onClick={() => saveFormValue()}>Save</Link></div></div>

                            </div>
                        </div>



                    </div>
                </div>}
            </div>
        </>
    )

}

export default View;