import moment from 'moment';
import {AddUserService,UpdateUserService,DeleteUserService,callApi} from '../services/user.server';

class Customer {
    static myInstance = null;
    constructor() {


    }

    static getInstance() {
        if (Customer.myInstance == null) {
            Customer.myInstance = new Customer();
        }

        return this.myInstance;
    }

   async AddCustomer(data){
        try{

            const userinfo = await AddUserService(data);
            return userinfo;

        }catch(err){
            return {status:0,error:err};

        }

   }
   async AssignWork(data){
    try{
        data['project_id']=1;
        const info = await callApi('/collection/assignwork',data);
        return info;

    }catch(err){
        return {status:0,error:err};

    }

}


   


   async UpdateCustomer(data){
    try{

        const userinfo = await UpdateUserService(data);
        return userinfo;

    }catch(err){
        return {status:0,error:err};

    }

}


async deleteCustomer(id){
    try{

        const userinfo = await DeleteUserService({id:id});
        return userinfo;

    }catch(err){
        return {status:0,error:err};

    }

}

async getCustomers(arg){
    try{

        const userinfo = await callApi('/user/getuser',arg);
        return userinfo;

    }catch(err){
        return {status:0,error:err};

    }

}

async getWorkType(arg){
    try{

        const userinfo = await callApi('/collection/getworktype',arg);
        return userinfo;

    }catch(err){
        return {status:0,error:err};

    }

}

async getkvfeeder33(arg){
    try{

        const info = await callApi('/collection/getkvfeeder33',arg);
        return info;

    }catch(err){
        return {status:0,error:err};

    }

}

async getkvfeeder11(arg){
    try{

        const info = await callApi('/collection/getkvfeeder11',arg);
        return info;

    }catch(err){
        return {status:0,error:err};

    }

}


async getSection(arg){
    try{

        const info = await callApi('/collection/getSection',arg);
        return info;

    }catch(err){
        return {status:0,error:err};

    }

}

async getTransformer(arg){
    try{

        const info = await callApi('/collection/getTransformer',arg);
        return info;

    }catch(err){
        return {status:0,error:err};

    }

}

async WorkStatusUpdate(arg){
    try{

        const info = await callApi('/collection/workstatusupdate',arg);
        return info;

    }catch(err){
        return {status:0,error:err};

    }

}


async reassign(arg){
    try{

        const info = await callApi('/collection/reAssignMgrationId',arg);
        return info;

    }catch(err){
        return {status:0,error:err};

    }

}


async uploaddata(arg){
    try{

        const info = await callApi('/report/uploadData',arg);
        return info;

    }catch(err){
        return {status:0,error:err};

    }

}


async saveSurvey(arg){
    try{

        const info = await callApi('/report/saveSurvey',arg);
        return info;

    }catch(err){
        return {status:0,error:err};

    }

}
async getUserWiseListOld(arg){
    try{

        const info = await callApi('/user/userwisereport',arg);
        return info;

    }catch(err){
        return {status:0,error:err};

    }

}
async getUserWiseList(arg){
    try{

        const info = await callApi('/user/userwisereportnew',arg);
        return info;

    }catch(err){
        return {status:0,error:err};

    }

}

async surveyApproval(arg){
    try{
        const info = await callApi('/collection/surveyApproval',arg);
        return info;
    }catch(err){
        return {status:0,error:err};

    }
}
















}
let Instance= Customer.getInstance();
export default Instance;