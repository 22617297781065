export default function Index(){

    return (
        <>       
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div>
                  <h4 className="card-title mb-0">Dashboard</h4>
                  
                </div>
              
              </div>
              <div className="c-chart-wrapper" >
                <canvas className="chart" id="main-chart" height="300"></canvas>
              </div>
            </div>
          </div>

         
      
        </>

    )
}