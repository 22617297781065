import { useEffect, useState } from 'react';
import { getUsers } from '../../services/user.server';
import moment from 'moment';
import ReportObj from '../../utility/reportclass';
import CustomerObj from '../../utility/customerclass';
import { Button, Pagination, Popconfirm, notification, Modal, Empty, AutoComplete, Input, DatePicker,Select } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';

import { useNavigate,Link } from "react-router-dom";
const { RangePicker }=DatePicker;
const searchResult = (data) => {

    return data.map((item, itemkey) => {
        return {
            value: item.first_name+' '+item.last_name+' '+item.mobile, label: (
                <>{`${item.first_name} ${item.last_name} (${item.mobile})`}</>
            ),
            key: item.id,
            user:item
        }
    })
}
const UserWiseReport = () => {
    const [customerList, setReportList] = useState({ totalrecord: 0, list: [] });
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 10 });
    const [feederlist,setfeederlist]=useState({})
    const [filter, setFilter] = useState({startdate:moment().format("YYYY-MM-DD"),enddate:moment().format("YYYY-MM-DD") });
    const [search, setSearch] = useState("");
    const [useroptions, setuseroptions] = useState([]);
    const [loader, setloader] = useState(false);
    const [usercount, setusercount] = useState([]);
    const [customerData, setCustomerData] = useState({});
    let navigate = useNavigate();
  

    useEffect(() => {       
        getUserWiseList();
        getFeederdata();
    }, []);


 

      async function getUserWiseList() {
        const flist = await CustomerObj.getUserWiseListOld(filter);
        console.log(flist);
        setCustomerData(flist?.customers);
        setusercount(flist?.records);

        //setfeederdata(filterdata);
            
       
      }


      async function getFeederdata() {
        
      let feederdata =await CustomerObj.getkvfeeder11({});
        console.log(feederdata);
       let feederFil= feederdata.reduce((pre,current)=>{
        pre[current['id']]=current;
        return pre;
        },{})

        setfeederlist(feederFil);
      }
 
    const handleSearch = (value,index) => {
        console.log(value);
        let filter = { searchkey: value };
        setloader(true);
        CustomerObj.getCustomers({ filter: filter }).then((data) => {
            setloader(false);
            console.log(searchResult(data.rows));
            setuseroptions(value ? searchResult(data.rows) : []);
        }).catch((err) => {

        })
    };

    const onSelect = (value,item) => {
        console.log(`Value ${value} index ${item}`);
        console.log(item);
     let name = 'user_id';
   
     setFilter((pre) => {
         console.log(pre);
         let newobj = {};
         newobj[name] = item?.user?.id;
         return { ...pre, ...newobj }

     })
     console.log('onSelect', value);

 };
    const handlePageChange = (page) => {

        setpage((pre) => {
            return { ...pre, currentpage: page }
        })
    }
   

 
    const onChangeWorktype = (value, item) => {

        console.log(value);
        console.log(item);
        let name = 'worktype_id';
   
        setFilter((pre) => {
           
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }
   
        })

       /* console.log(`selected ${value}`);
        console.log(option);
    
        formData['work_id'] = value;
        setformData(formData);
        setworktype(value);
        console.log(formData);
        if(value==3 || value==2 || value==4){
          setworkordercodeedit(0);
        }else{
          setworkordercodeedit(1);
        }*/
    
      }
      const onSearch = (value) => {
        console.log('search:', value);
      };
      const markcomplete=async(item,key,status=1)=>{
       let data= await CustomerObj.WorkStatusUpdate({id:item.id,status:status});
       console.log(data);
       if(data==true){
            let list =[...customerList?.list];
            list[key]['status']=status;
            setReportList((pre)=>({...pre,list:list}));
            notification.success({message:'Status has been changed successfully'});
       }
         
      }

      function convertToCSV(json) {
        var fields = Object.keys(json[0])
        var replacer = function(key, value) { return value === null ? '' : value } 
        var csv = json.map(function(row){
          return fields.map(function(fieldName){
            return JSON.stringify(row[fieldName], replacer)
          }).join(',')
        })
        csv.unshift(fields.join(',')) // add header column
        csv = csv.join('\r\n');
        return csv;


      }

      function downloadCSV(json) {
        const csv = convertToCSV(json);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = common.dateFormaturl()+'.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      const downloaddata=async()=>{

        let rowdata=[...usercount];
        let filderdata=rowdata.map((item)=>{
            let user=customerData[item?.user_id] !=null?customerData[item?.user_id]:"No Name" ;
                                       
            let feeder=feederlist[item?.kvfeeder11_id] !=null?feederlist[item?.kvfeeder11_id]?.feeder_name:"";
            item['username']=user;
            item['feedername']=feeder;
            delete  item['user_id'];
            delete  item['kvfeeder11_id'];
            return item;

        });
        console.log(filderdata)
        downloadCSV(filderdata);

      }

      const onChangeDate = (dates, dateStrings) => {
        console.log(dates);
        if (dates) {
            setFilter((pre) => {
              
                let newobj = {};
                newobj['startdate'] =  dates[0].format("YYYY-MM-DD");
                newobj['enddate'] =  dates[1].format("YYYY-MM-DD");
                return { ...pre, ...newobj }
    
            })
          
        } else {
           
            setFilter((pre) => {
              
                let newobj = {};
                newobj['startdate'] = moment().format("YYYY-MM-DD");
                newobj['enddate'] = moment().format("YYYY-MM-DD");
                return { ...pre, ...newobj }
    
            })
        }
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        getUserWiseList();
       
    }
    return (
        <>
           { <div class="callout callout-info bg-white">

                <form class="row row-cols-lg-auto g-3 align-items-center" onSubmit={(e) => handleSubmit(e) }>
                <div class="col-md-2">
                <RangePicker
                defaultValue={[moment(),moment()]}
      ranges={{
        Today: [moment(), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      }}
      onChange={onChangeDate}
    />
                       
                    </div>

                    <div class="col-md-2">
                        <br/>
                    <div class="form-check">
                   
                            </div>
                    </div>
                   

                    <div class="col-12">
                  
                        <button className="btn btn-primary" >Search</button>
                    </div>

                    <div class="col-12">
                   
                    <button className="btn btn-primary" onClick={(e)=>{downloaddata()}}>download</button>
                    </div>

                    
                        </form>
    </div>}
            <div className="card mb-4">
                <div className="card-header">User List  
               
                </div>
                <div className="card-body">
                    <div className="tab-content rounded-bottom">
                        
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th>User</th>
                                    <th>Feeder</th>  
                                    <th>Total</th>  
                                    <th>Pasted</th>  
                                    <th>Not Pasted</th>  
                                    <th>Approval</th>   
                                    <th>Rejected</th> 
                                    <th>Pending</th>                                    
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    usercount.map((item, itemKey) => {
                                        let user=customerData[item?.user_id] !=null?customerData[item?.user_id]:"No Name" ;
                                       
                                       let feeder=feederlist[item?.kvfeeder11_id] !=null?feederlist[item?.kvfeeder11_id]?.feeder_name:"";
                                        return (
                                            <tr key={item.id}>
                                                <td >{((paging.currentpage - 1) * paging.itemlimit) + (itemKey + 1)}</td>
                                                
                                                <td >{`${user}`}</td>                                               

                                        <td>{feeder}</td>
                                        <td>{item?.count}</td>
                                        <td>{item?.pasted}</td>
                                        <td>{item?.notpasted}</td>
                                        <td>{item?.approval}</td>
                                        <td>{item?.rejected}</td>
                                        <td>{item?.pending}</td>
                                              
                                            </tr>

                                        )

                                    })
                                }

                            </tbody>
                        </table>
                      
                    </div>
                </div>
            </div>
        </>
    )

}

export default UserWiseReport;