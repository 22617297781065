import React, { useEffect,useState } from 'react'
import { GoogleMap, useJsApiLoader,MarkerClusterer,Marker,Polyline } from '@react-google-maps/api';
import common from '../../utility/commonclass';
import ReportObj from '../../utility/reportclass';
import LayerForm from './LayerForm';
import { Button, Pagination, Popconfirm, notification, Modal, Empty, AutoComplete, Input, DatePicker, Drawer,Select,Spin } from 'antd';



function MyComponent(props) {
    let width=(props?.width !='undefined')?props?.width:'100%';
    const containerStyle = {
        width: width,
        height: '90vh'
      };
      const center = (props?.center !='undefined')?props?.center:{
        lat: 28.5355,
        lng: 77.3910
      };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAgIVtPj9I-jXY3fOUkV8k9CArQRQ7dkhc"
  })
  const addlayer=props?.addlayer;
  const [polelist, setmapItems] = React.useState([]);
  const [polemarkerlist, setpolemarkerlist] = React.useState({});
  const [cabellist, setmapPolyline] = React.useState([]);
  const [label, setlabel] = useState(props.label);
  const [tmpmarker, settmpmarker] = useState({});
  const[markerModalConfirm,setmarkerModalConfirm]=useState(false);
  const [map, setMap] = React.useState(null);
  const[layerFormVisible,setlayerFormVisible]=useState(false);
  const[selectedPoles,setselectedPoles]=useState([]);

  useEffect(()=>{

        resetAddlayer();

  },[addlayer]);


  useEffect(()=>{

    console.log(props.list);
    let p=[];
    let c=[];
    for(let i=0; i<=props?.list?.length;i++){
       // console.log(props?.list[i]);
        if(props?.list[i]?.ext_id==3 || props?.list[i]?.ext_id==17){
           c.push(props?.list[i]); 
        }else{
            if(common.isEmpty(props?.list[i])==false){
                p.push(props?.list[i]); 
            }
           
        }

    }
  
    setmapItems(p);
    setmapPolyline(c);
  },[props.list])


  useEffect(()=>{
    console.log(props.label);
    setlabel(props.label);
  },[props.label])

  const resetAddlayer=()=>{
    setlayerFormVisible(false);
    settmpmarker({});
    setselectedPoles([]);
}

const savelayer=(data)=>{  
    props.savelayer(data);
    resetAddlayer();



}


  const onLoad = React.useCallback(function callback(map) {
    console.log('load')
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    //const bounds = new window.google.maps.LatLngBounds(center);
    //map.fitBounds(bounds);

    setMap(map)
  }, [])

  function createKey(location) {
    return location.lat + location.lng
  }

  function getlocation(item) {
      try{
        if(item?.coordinates =='undefined'){
            return { lat: 0, lng: 0 }
        }
     
      let coordinates = JSON.parse(item?.coordinates);
     // console.log({ lat: coordinates[0]['latitude'], lng: coordinates[0]['longitude'] });
      return { lat: parseFloat(coordinates[0]['latitude']), lng: parseFloat(coordinates[0]['longitude']) }
      }catch{
        return { lat: 0, lng: 0 }

      }
      
    //return location.lat + location.lng
  }

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const getColor = (item) => {
    let url = "http://survey.geomaticxmap.in/api/images/";
    let color = "black.png";
    if (item?.ext_id == 2) {  // Pole
        if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id == 0) { // 33 KV
            color = "33kv.png";
            if(item?.no_branch > 0 && item?.cut_point > 0 ){
                color = "33kv_branch_cut.png";
            }else if(item?.no_branch > 0 && item?.cut_point == 0 ){
                color = "33kv_branch.png";
            }else if(item?.no_branch == 0 && item?.cut_point > 0 ){
                color = "33kv_branch.png";
            }
        } else if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id > 0 && item?.dt_id == 0) { //11
            color = "11kv.png";
            if(item?.no_branch > 0 && item?.cut_point > 0 ){
                color = "11kv_branch_cut.png";
            }else if(item?.no_branch > 0 && item?.cut_point == 0 ){
                color = "11kv_branch.png";
            }else if(item?.no_branch == 0 && item?.cut_point > 0 ){
                color = "11kv_branch.png";
            }
        } else if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id > 0 && item?.dt_id > 0) { // LT
            color = "lt.png";
            if(item?.no_branch > 0 && item?.cut_point > 0 ){
                color = "lt_branch_cut.png";
            }else if(item?.no_branch > 0 && item?.cut_point == 0 ){
                color = "lt_branch.png";
            }else if(item?.no_branch == 0 && item?.cut_point > 0 ){
                color = "lt_branch.png";
            }
        }

    } else if (item?.ext_id == 4) { // comms_device
        //color="black";

    } else if (item?.ext_id == 6) { //Connector Point
        // color="blueviolet";

    } else if (item?.ext_id == 8) { //Consumer_Meter
        //  color="aqua";

    } else if (item?.ext_id == 9) { //energy_source
        // color="green";

    } else if (item?.ext_id == 10) { //energy_storage
        // color="greenyellow";

    } else if (item?.ext_id == 12) { //Measuring Equipment
        // color="brown";

    } else if (item?.ext_id == 13) { //Meter
        //  color="skyblue";

    } else if (item?.ext_id == 14) { //Transformer
        color = "transformer.png";

    } else if (item?.ext_id == 15) { //Protective Equipment
        // color="darkorange";

    } else if (item?.ext_id == 16) { //service_point
        // color="yellow";


    }
    return url + color;


}

const getLabel = (item)=>{
      
  if(common.isEmpty(label[item?.ext_id])==false){
          let code="";

          if(label[item?.ext_id]['status']==true){

              label[item.ext_id]['type'].map((codeval)=>{
                  if(codeval=='code'){
                      code  =code +' '+(item?.record_number !=""?item?.record_number:"TMP : "+item?.mobile_record_number ) +" :: ";

                  }else  if(codeval=='paint_code'){
                      code  =code +' '+item?.paint_code +" :: ";
                  }else  if(label[item.ext_id]['type']=='nominal_voltage'){
                      code  =code +' '+item?.nominal_voltage +" :: ";
                  }else  if(label[item.ext_id]['type']=='composite_type'){
                      code  =code +' '+item?.composite_type +" :: ";
                  }else  if(label[item.ext_id]['type']=='element_type'){
                      code  =code +' '+item?.element_type +" :: ";
                  }else  if(label[item.ext_id]['type']=='no_of_hv'){
                      code  =code +' '+item?.no_of_hv +" :: ";
                  }else  if(label[item.ext_id]['type']=='no_of_mv'){
                      code  =code +' '+item?.no_of_mv +" :: ";
                  }else  if(label[item.ext_id]['type']=='no_of_mv'){
                      code  =code +' '+item?.no_of_mv +" :: ";
                  }else  if(label[item?.ext_id]['type']=='no_of_lv'){
                      code  =code +' '+item?.no_of_lv +" :: ";
                  }else  if(label[item.ext_id]['type']=='no_branch'){
                      code  =code +' '+item?.no_branch +" :: ";
                  }else  if(label[item.ext_id]['type']=='jumper'){
                      code  =code +' '+item?.jumper +" :: ";
                  }else  if(label[item.ext_id]['type']=='cut_point'){
                      code  =code +' '+item?.cut_point +" :: ";
                  }

              })                    

          }

          return code;
      
  }else{
      return "";
  }

  
}

const ChangePointLocation=async (e,item,key)=>{

    console.log(e.latLng?.lat());
    console.log(e.latLng?.lng());
    let location=[];
    location.push({latitude:e.latLng?.lat(),longitude:e.latLng?.lng()});
    await ReportObj.ChangePointLocation({coordinates:location,id:item?.id});
   let p=[...polelist];
   p[key].coordinates=JSON.stringify(location);
  //console.log(p[key].coordinates);
  //p[key]?.coordinates=JSON.stringify(location);
    setmapItems(p);
    

}


const ChangeTmpPointLocation=async(e)=>{

    settmpmarker((pre)=>({...pre,location:{lat: e?.latLng.lat(), lng: e?.latLng.lng()}}));
    //settmpmarker({location:{lat: e?.latLng.lat(), lng: e?.latLng.lng()}});
    setmarkerModalConfirm(true);
}



const Modalok = () => {
    setmarkerModalConfirm(false);
    openAddLayerForm();

  };
  const hideModal = () => {
    setmarkerModalConfirm(false);
  };
  
const openAddLayerForm=()=>{
setlayerFormVisible(true);
}

const poleTap=async (ele)=>{
    console.log(ele);
    let coordinates=JSON.parse(ele?.coordinates);
    let code=(common.isEmpty(ele?.record_number)==false)?ele?.record_number:ele?.mobile_record_number;
    let type=ele?.nominal_voltage=='11KV'?'MV':'LV';
    let tmp={id:ele?.id,location:coordinates[0],code:code,type:type}
    selectedPoles.push(tmp);
    await setselectedPoles(selectedPoles);
    console.log(selectedPoles);
    notification.success({key:"tappole",message:code+" pole has been selected" });
    
    console.log(addlayer);
    console.log(selectedPoles.length);
    if(addlayer==14 &&  selectedPoles.length == 1){
        console.log("dtselcted")
        openAddLayerForm();
        return true;
    }
    if((addlayer==3 || addlayer==17) &&  selectedPoles.length ==2){
        openAddLayerForm();
    }
    //setselectedPoles

}

  return isLoaded ? (
     <> <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}       
        draggable={false}
        onMouseDown={(e)=>{

            
        }}

        onClick={(e) => {
            console.log(e?.latLng.lat());
            console.log(e?.latLng.lng());
            console.log("test click",addlayer);
            if(addlayer==2){
                settmpmarker({location:{lat: e?.latLng.lat(), lng: e?.latLng.lng()}});
               
                setmarkerModalConfirm(true);
            }
           

            // TODO: Make this less hacky
          
        }}
      >
          {Object.keys(tmpmarker).length > 0 &&  <Marker key="tmp-marker"              
              position={tmpmarker?.location} 
              icon={'http://survey.geomaticxmap.in/api/images/black.png'}
              draggable={true}  
              //animation={(location?.id==props?.selected)?1:null} 
              onDragEnd={(e)=>{ChangeTmpPointLocation(e);}}  
              optimized={true}         
              //onClick={(e, mitem) => { props?.openElementDetail(location?.id, pointkey) }}
              />
              }
        {/*{getLabel(location)}*/}
        { /* Child components, such as markers, info windows, etc. */ }
        
        <MarkerClusterer minimumClusterSize={5} onClick={(c,e)=>{
          
        }}>
          {(clusterer) =>
            polelist.map((location,pointkey) => (
              <Marker key={location?.id} 
              id={location?.id} 
              position={getlocation(location)} 
              icon={(location?.id==props?.selected)?'http://survey.geomaticxmap.in/api/images/selected.png':getColor(location)} 
              label={getLabel(location)}
              clusterer={clusterer}
              draggable={true}  
              //animation={(location?.id==props?.selected)?1:null} 
              onDragEnd={(e)=>{ChangePointLocation(e,location,pointkey);}}  
              optimized={true}         
              onClick={(e, mitem) => {                 
                   if(common.isEmpty(addlayer)==true){
                    props?.openElementDetail(location?.id, pointkey) 
                   }else{
                    poleTap(location);

                   } 
               
            
            }}/>
            ))
          }
        </MarkerClusterer>
        {cabellist.length > 0 && 

cabellist.map(item=>{
    let coordinates = JSON.parse(item?.coordinates);
    let strokeColor = "#FF0000";
    let strokeWeight = 1;
    let path=[]

    if(coordinates.length > 1){
        path = [
            { lat: coordinates[0]['latitude'], lng: coordinates[0]['longitude'] },
            { lat: coordinates[1]['latitude'], lng: coordinates[1]['longitude'] },
        ]
    }
    

    if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id == 0) {
        strokeColor = "#FF0000";
        strokeWeight = 3;
    }
    if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id > 0 && item?.dt_id == 0) {
        strokeColor = "#0000FF";
        strokeWeight = 2;
    }
    if (item?.kvfeeder33_id > 0 && item?.kvfeeder11_id > 0 && item?.dt_id > 0) {
        strokeColor = "#008000";
        strokeWeight = 1;
    }
    return (<Polyline

        key={item?.id}
        path={path}
        options={{
            strokeColor: strokeColor,
            strokeOpacity: 0.8,
            strokeWeight: strokeWeight,
            fillColor: strokeColor,
            fillOpacity: 0.35,
            clickable: true,
            draggable: true,
            editable: false,
            visible: true,
            radius: 30000,
            zIndex: 1,
            icons: [{ 
                icon: "hello",
                offset: '0',
                repeat: '10px'
              }],
        }}
    />)
})

                    }
      </GoogleMap>
      <Modal
        title="Confirm"
        visible={markerModalConfirm}
        onOk={Modalok}
        onCancel={hideModal}
        okText="Open Form"
        cancelText="Adjust Marker"
      >
       <p></p>
      </Modal>
      {layerFormVisible==true && <LayerForm savelayer={savelayer} selectedPoles={selectedPoles} location={tmpmarker?.location} cancel={(e)=>{resetAddlayer();setlayerFormVisible(false)}} visible={layerFormVisible} ext_id={addlayer}></LayerForm>}
      </>
  ) : <></>
}

export default React.memo(MyComponent)