import config from '../config/index'
import common from '../utility/commonclass';
class Auth{
    static myInstance = null;
    constructor(){
      

    }

    static getInstance() {
        if (Auth.myInstance == null) {
            Auth.myInstance = new Auth();
        }

        return this.myInstance;
    }

    setUserSession(session){        
        localStorage.setItem(config.sessionkey,JSON.stringify(session));
    }


    getUserSession(){
     const data=localStorage.getItem(config.sessionkey);    
     return (common.isEmptyObject(data)===true)?false:JSON.parse(data);
        
    }

  
    removeUserSession(){
        localStorage.removeItem(config.sessionkey);
    }


}
let Instance= Auth.getInstance();
export default Instance;
