import { useEffect, useState } from 'react';
import { getUsers } from '../../services/user.server';
import workorderservice from '../../services/workorder.service'
import { Button, Pagination, Popconfirm, AutoComplete, notification, Spin, Modal,Select } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import { useNavigate, Link } from "react-router-dom";

const WorkOrderLoadList = () => {
    const [workorderData, setworkorderData] = useState({ load: true, features: [] });
    const [options, setOptions] = useState([]);
    const [customerOptions, setcustomerOptions] = useState([]);

    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 10 });
    const [visible, setvisible] = useState(false);
    const [modalcontent, setmodalcontent] = useState("");
    const [capability, setcapability] = useState("stagging.cs_work_order");
    const [filter, setfilter] = useState({});
    const [selectedWorkOrder, setWorkOrder] = useState({});
    const [selectedCustomer, setselectedCustomer] = useState({});
    const [loader, setloader] = useState(false);

    const NameFilterCapability = ["electric.cs_work_order", "gis.cs_work_order", "stagging.cs_work_order"];
    const wsfCapabilityUrl = 'https://gisuat.tpsouthernodisha.com/wfs/GetCapabilities?service=wfs&amp;request=test';
    const typeName="stagging.cs_work_order";
    const wsfUrl = `https://gisuat.tpsouthernodisha.com/wfs/GetFeature/1.1.0?outputFormat=application/json&request=mobile_request&version=1.1.0&service=WFS&typeName=${typeName}&srsName=sw:world_longlat_wgs84_degree`;
    // Work Order Number  &filter=<Filter><PropertyIsEqualTo><PropertyName>name</PropertyName><Literal>CARD BOARD FACTORY, ALOK NAGAR</Literal></PropertyIsEqualTo></Filter>


    let navigate = useNavigate();

    const onSearch = (searchText) => {
        setOptions([]);
    };
    const onSelect = (data) => {
        setcapability(data);
        console.log('onSelect', data);
    };


    const onSelectCustomer = (data,option)=>{

        console.log(data);
        console.log(option);
        setselectedCustomer(option);
        return "Test Ravi";
    }


    useEffect(() => {
        /*async function getData() {
            const xmldata = await workorderservice.getCapability(wsfCapabilityUrl);
            console.log(xmldata);
            setOptions(xmldata);
        }*/
        async function getCustomrData() {
            const customerlist = await getUsers();
            let options = customerlist.rows.map((item) => {
                return { value: `${item.first_name} ${item.last_name}`, key: item.id }

            })
            setcustomerOptions(options);
        }

        //getData();
        getCustomrData();


    }, []);

    function generateLayerUrl(){
        let url = wsfUrl.replace('{RS_TYPENAME}', capability);
        console.log(filter);
        console.log(url);
        if (NameFilterCapability.includes(capability)){
            console.log(filter);
            if(typeof(filter.wo_type)=='string' && filter.wo_type.length > 0 && typeof(filter.wo_status) =='string' && filter.wo_status.length > 0){

                url = url + '&filter=<Filter><And><PropertyIsEqualTo><PropertyName>tp_wo_type</PropertyName><Literal>' + filter.wo_type + '</Literal></PropertyIsEqualTo><PropertyIsEqualTo><PropertyName>tp_wo_status</PropertyName><Literal>' + filter.wo_status + '</Literal></PropertyIsEqualTo></And></Filter>';

            }else if(typeof(filter.wo_type)=='string' && filter.wo_type.length > 0 ){
                url = url + '&filter=<Filter><And><PropertyIsEqualTo><PropertyName>tp_wo_type</PropertyName><Literal>' + filter.wo_type + '</Literal></PropertyIsEqualTo></And></Filter>';
            }


        }
       return url;
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(capability);
        setWorkOrder({});
       
        
        if (!capability) {
            notification.error({

                message: 'Please Select  Capability',
                description: 'description.',
            });
            return false;
        }
        setworkorderData((pre) => {
            return { features: [], load: false };
        })

        try{
           
            let url=  generateLayerUrl();
       
        const xmldata = await workorderservice.getWorkOrderData(url);
       // console.log(xmldata);

        if (typeof xmldata !== 'undefined' && xmldata['features']) {
            setworkorderData((pre) => {
                return { ...xmldata['features'], features: xmldata['features']['features'], load: true };
            })
        } else {
            notification.error({

                message: 'There is some error from WFS Api',
                description: 'description.',
            });
            setworkorderData((pre) => {
                return { load: true };
            })
        }
    }catch(e){
        notification.error({

            message: 'There is some error from WFS Api',
            description: 'description.',
        });
        setworkorderData((pre) => {
            return { load: true };
        })
    }

        // console.log




    }



    const savelayer = async () => {
        console.log(selectedCustomer.key);
        if (!selectedCustomer.key) {
            notification.error({
                message: 'Please Select  User',
                description: 'description.',
            });
            return false;
        } 
        if (workorderData.features.length > 0 && selectedCustomer.key > 0) {


            let url=  generateLayerUrl();

            setloader(true);
            const responce = await workorderservice.saveWorkOrderLayer({ url: url, capability: capability,userid:selectedCustomer.key });
            setloader(false);
            if (responce.status == 1) {
                setworkorderData({ features: [] });
                notification.success({ key: 'save', message: 'Save Successfully' });
            } else {
                notification.error({

                    message: 'Some error',
                    description: 'description.',
                });

            }
        }


    }

    const AssignLayer = async (e) => {
        if (Object.keys(selectedWorkOrder).length == 0) {
            notification.error({
                message: 'Please Select  Workorder',
                description: 'description.',
            });
            return false;


        } 

        if (!selectedCustomer.key) {
            notification.error({
                message: 'Please Select  User',
                description: 'description.',
            });
            return false;
        } 
        console.log(workorderData);
        let insertdata={};
        insertdata['bbox']=workorderData['bbox'];
        insertdata['crs']=workorderData['crs'];
        insertdata['type']=workorderData['type'];
        insertdata['features']=selectedWorkOrder;
        insertdata['userid']=selectedCustomer.key;  
        insertdata['capability']=capability;  
        
        console.log(insertdata);
        let workorderRes=await workorderservice.AssignWorkOrder(insertdata);
        setworkorderData(()=>{
            return { load: true, features: [] }
        }) 
        notification.success({message:'Save Successfully'})

        //setIsSubscribed(current => !current);
    }
    const viewDetail = async (key) => {
        setmodalcontent(JSON.stringify(workorderData.features[key]));
        setvisible(true);
        console.log(workorderData.features[key])
    }

    const handleCancel = (key) => {

        setvisible(false);
    }

    const workorderFilterHandel = (e,name) => {

       let tmpfilter=filter;
       tmpfilter[name]=e.target.value;
        setfilter((pre) => {
            return { ...tmpfilter }
        })
    }

    const handleFilter=(value,option)=>{
        console.log(value);
        console.log(option);
       
        setfilter((pre) => {
            return { ...pre, wo_type: value }
        })
    }

    const handleChange = (e, key) => {
        console.log(key);
        if (e.target.checked) {
            selectedWorkOrder[workorderData.features[key].id] = workorderData.features[key];
            console.log(selectedWorkOrder);
            console.log(workorderData.features[key].id)
            setWorkOrder(selectedWorkOrder);
            console.log('✅ Checkbox is checked');
        } else {
            delete selectedWorkOrder[workorderData.features[key].id];
            console.log('⛔️ Checkbox is NOT checked');
            setWorkOrder(selectedWorkOrder);
        }
        console.log(selectedWorkOrder);
        //  setIsSubscribed(current => !current);
    };


    return (
        <>
            { loader===true && <div className="content-overlay" >
                     <div className="overlay-content"><img src={process.env.PUBLIC_URL + '/images/loading.gif'} alt="" width="50"></img></div>
                    </div>
                }
            <div class="callout callout-info bg-white">
                
            
                <form class="row row-cols-lg-auto g-4 align-items-center" onSubmit={(e) => { handleSubmit(e) }}>

                    <div class="col-md-4">
                        <label>Work Order Type</label>
                        <Select
                        name="wo_number"
    showSearch
    style={{
      width: 200,
    }}
    onChange={handleFilter}
    placeholder="Select Work Order Type"  
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
   
    options={[
      {
        value: 'New Connection',
        label: 'New Connection',
      },
      {
        value: 'NW Planning',
        label: 'NW Planning',
      },
      /*{
        value: 'Maintainance',
        label: 'Maintainance',
      },*/
      {
        value: 'Consumer',
        label: 'Consumer',
      },
      {
        value: 'Meter Replacement',
        label: 'Meter Replacement',
      }
      
    ]}
  />
                       
                    </div>

                    {NameFilterCapability.includes(capability) === true && 1==2 && 
                        <div class="col-md-4 name-filter-box">
                             <label>Work Order Number</label>
                            <input type="text" class="form-control" name="wo_number" onChange={(e) => { workorderFilterHandel(e,'wo_number') }} placeholder="Work Order Number" />

                        </div>

                    }

                    {NameFilterCapability.includes(capability) === true  && 
                        <div class="col-md-4 name-filter-box">
                             <label>Work Order Status</label>
                            <div className="ant-select ant-select-single ant-select-show-arrow ant-select-show-search"><input type="text" class="form-control" name="wo_status" onChange={(e) => { workorderFilterHandel(e,'wo_status') }} placeholder="Work Order Status" /></div>

                        </div>

                    }


                    <div class="col-md-4">
                       
                        <button className="btn btn-primary" disabled={(workorderData.load == false) ? true : false}>Load WFS</button>
                    </div>
                </form>

            </div>
            <div className="card mb-4">
                <div className="card-header">Workorder List</div>
                <div className="card-body">

               {workorderData.features.length > 0 &&  <AutoComplete
                            options={customerOptions}
                            style={{
                                width: 200,
                            }}
                            onSelect={onSelectCustomer}
                            // onSearch={onSearch}
                            placeholder="input here"
                            filterOption={(inputValue, option) => {                                
                                return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1

                            }

                            }
                        />
                    }

                    {NameFilterCapability.includes(capability) === false && workorderData.features.length > 0 &&
                        <>
                            <Link to="" onClick={() => savelayer()}>Save Layer</Link></>
                    }

                    {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 &&
                        <>
                            

                            <Link to="" onClick={(e) => AssignLayer(e)}>Assign Work Order</Link></>
                    }
                    <div className="tab-content rounded-bottom">
                        {workorderData.load == true &&
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th >#</th>
                                        {/*<th>Work Order ID</th>*/}
                                        {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>Work Order Number</th>}
                                        {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>Work Order Status</th>}
                                        {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>Initiation Date</th>}
                                        {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>Activation Date</th>}
                                        {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>Inspector</th>}
                                        {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>Contractor</th>}
                                        {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>Remark</th>}
                                        
                                        

                                        <th>Type</th>
                                        <th>Geometry</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        workorderData.features.map((item, itemKey) => {
                                            return (
                                                <tr key={item.id}>
                                                    {NameFilterCapability.includes(capability) === true && <td ><input type="checkbox" onChange={(e) => handleChange(e, itemKey)}></input> </td>}
                                                    {NameFilterCapability.includes(capability) === false && <td >{itemKey + 1}</td>}
                                                    {/*<td >{item.id}</td>*/}
                                                    {NameFilterCapability.includes(capability) === true && <th>{workorderData.features[itemKey]['properties']['Work Order Number']}</th>}
                                                    {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>{ workorderData.features[itemKey]['properties']['Wo Status']}</th>}
                                                    {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>{ workorderData.features[itemKey]['properties']['Activation Date']}</th>}
                                                    {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>{ workorderData.features[itemKey]['properties']['Completion Date']}</th>}
                                                    {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>{ workorderData.features[itemKey]['properties']['Inspector']}</th>}
                                                    {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>{ workorderData.features[itemKey]['properties']['Contractor']}</th>}
                                                    {NameFilterCapability.includes(capability) === true && workorderData.features.length > 0 && <th>{ workorderData.features[itemKey]['properties']['Remarks']}</th>}
                                                 
                                                    <td >{item.type}</td>
                                                    <td>{JSON.stringify(item.geometry)}</td>
                                                    <td><Link to="" onClick={() => viewDetail(itemKey)}>View</Link></td>

                                                </tr>

                                            )

                                        })
                                    }

                                </tbody>
                            </table>
                        }
                        {workorderData.load == false && <div class="text-center"><Spin size="large" /></div>}

                    </div>
                </div>
            </div>
            <Modal
                title="Basic Modal"
                visible={visible}
                onCancel={() => handleCancel()}
            >
                <p><code>{modalcontent}</code></p>
            </Modal>
        </>
    )

}

export default WorkOrderLoadList;