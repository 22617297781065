import { useEffect, useState } from 'react';
import { getUsers } from '../../services/user.server';
import workorderservice  from '../../services/workorder.service'
import { Button, Pagination, Popconfirm , AutoComplete,notification } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import { useNavigate,Link } from "react-router-dom";

const WorkOrderList = () => {
    const [workorderData, setworkorderData] = useState({  list: [] });
    const [customer, setcustomerData] = useState({  list: [] });
    const [activeAssign,setActiveAssign]=useState(0);
    const [options, setOptions] = useState([]);
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 10 });
    const [filter, setFilter] = useState("");
    const [capability, setcapability] = useState(""); 
    const wsfCapabilityUrl='https://gisuat.tpsouthernodisha.com/wfs/GetCapabilities?service=wfs&amp;request=test';
    const wsfUrl='https://gisuat.tpsouthernodisha.com/wfs/GetFeature/1.1.0?outputFormat=application/Json&request=WFS&version=1.1.0&service=WFS';


    
    let navigate = useNavigate();

    const onSearch = (searchText) => {
        setOptions([]);
    };
     
     

    useEffect(() => {
      async function getData (){
        const xmldata = await  workorderservice.getlayers();
        setworkorderData({...xmldata,list:xmldata.data});

        const customerlist = await  getUsers();
        
      let options=  customerlist.rows.map((item)=>{
        return {label:`${item.first_name} ${item.last_name}`, value:item.id}

        })
        setOptions(options);
       }
      
       getData();
       

    }, []);




    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(capability);
       if(!capability){
        notification.error({
           
            message: 'Please Select  Capability',
            description: 'description.',
          });
        return false;
       }

       let url=wsfUrl+'&typeName='+capability;
       const xmldata = await  workorderservice.getWorkOrderData(url);
       setworkorderData((pre)=>{
        return { ...xmldata['features'],features:xmldata['features']['features'] };
       })

    
    }
   
    
   
    const savelayer=async ()=>{

        if(workorderData.features.length > 0){
         const responce=   await  workorderservice.saveWorkOrderLayer(workorderData);
      
         if(responce.status==1){

            setworkorderData({  features: [] });
         }else{


         }
        }
         
       // console.log('Save Data');
    }


    const onSelect = async (data) => {

        const responce=   await  workorderservice.Assign({id:data,layerid:activeAssign});
        setActiveAssign(0);


       
    };

    const assignrow=(id)=>{
        setActiveAssign(id);


    }



    return (
        <>
          
            <div className="card mb-4">
                <div className="card-header">Workorder List</div>
                <div className="card-body">
                   
                    <div className="tab-content rounded-bottom">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th>Action</th>     
                                    <th>CRS</th>
                                    <th>Feature Type</th>
                                    <th>Type</th>
                                    <th>bbox</th>
                                    <th>User</th>
                                    
                                                                   
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    workorderData.list.map((item, itemKey) => {
                                        return (
                                            <tr key={item.id}>
                                                <td >{(itemKey + 1)}</td>
                                                <td>
                                                    {activeAssign!==item.id && <Link to="" onClick={(e)=>{assignrow(item.id)}}>Assign</Link>}

{activeAssign===item.id && <AutoComplete
style={{ width: 200 }}
options={options}
placeholder="Search user"
onSelect={onSelect}
filterOption={(inputValue, option) =>{
//console.log(option)
return  option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
}

}
/>
}

</td>
                                                <td >{item.crs}</td>
                                                <td >{item.feature_types}</td>
                                                <td >{item.type}</td>
                                             <td><code>{JSON.stringify(item.bbox)}</code></td>
                                             <td >{item.customer && (item.customer.first_name+' '+item.customer.last_name)}</td>
                                               
                                               
                                            </tr>

                                        )

                                    })
                                }

                            </tbody>
                        </table>
                        
                    </div>
                </div>
            </div>
        </>
    )

}

export default WorkOrderList;