export default function Error(){


return(
<>
Error
</>

) 

}