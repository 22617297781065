import axios from '../config/axios';
import { notification } from 'antd';
import XMLParser from 'react-xml-parser';
const workorder = {
  getCapability: (url) => axios.post(url).then((res)=>{  
        let xml = new XMLParser().parseFromString(res.data); 

       let nn= xml.getElementsByTagName('wfs:FeatureTypeList');
    
     const newdata=   nn[0].children.map((item)=>{      
          return {label:item.getElementsByTagName('wfs:Name')[0].value,
            title: item.getElementsByTagName('wfs:Title')[0].value,
            value: item.getElementsByTagName('wfs:Name')[0].value
        };
        });
         return newdata;
    }).catch((err) =>{ console.log(err); notification.error({message: err.message})}),

  getWorkOrderData: (url) => axios.post(url).then((res)=>{  
      return res.data;
  }).catch((err) =>{ console.log(err); notification.error({message: err.message})}),

  saveWorkOrderLayer:(data) => axios.post('/workorder/save',data).then((res)=>res.data).catch((err) =>{ console.log(err); notification.error({message: err.message})}),
  getlayers:(data) => axios.post('/workorder/list',data).then((res)=>res.data).catch((err) =>{ console.log(err); notification.error({message: err.message})}),
  Assign:(data) => axios.post('/workorder/assign',data).then((res)=>res.data).catch((err) =>{ console.log(err); notification.error({message: err.message})}),
  AssignWorkOrder:(data)=> axios.post('/workorder/assignworkorder',data).then((res)=>res.data).catch((err) =>{ console.log(err); notification.error({message: err.message})}),



}
export default workorder;