
import {Navigate} from 'react-router-dom';
import authClass from './utility/authclass';
const Protected =({children} )=>{           
    if(authClass.getUserSession()===false){
        return <Navigate to="/" replace />;
    }
   
    return children;


}

export default Protected;