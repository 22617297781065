import { useEffect, useState } from 'react';
import config from '../../config/index';
import AuthObj from '../../utility/authclass';

import ReportObj from '../../utility/reportclass';
import CustomerObj from '../../utility/customerclass';
import { Button, Pagination, Popconfirm, notification, Modal, Empty, AutoComplete, Input, DatePicker,Spin } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
const photofield = ["tp_uq_pole_photo", "tp_uq_pole_paint_photo"];
const { RangePicker } = DatePicker;

const searchResult = (data) => {

    return data.map((item, itemkey) => {
        return {
            value: item.first_name+' '+item.last_name+' '+item.mobile, label: (
                <>{`${item.first_name} ${item.last_name} (${item.mobile})`}</>
            ),
            key: item.id,
            user:item
        }
    })
}

const Reassign = () => {
    const [customerList, setReportList] = useState({ totalrecord: 0, list: [], uploadurl: "" });
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 10 });
    const [filter, setFilter] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageData, setImageData] = useState({ url: "", list: [] });
    const [useroptions, setuseroptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loader, setloader] = useState(false);
    const [downloadtype, setdownloadtype] = useState("geojson");
    const [currentUser, setCurrentUser] = useState({});
    const [section,setsection]=useState({list:[],options:[]})
    const [layers, setLayers] = useState([]);
    const [kv33,setkv33]=useState([])
    const [kv11,setkv11]=useState([])
    const [dtlist,setdtlist]=useState([])
    const [feederdata, setfeederdata] = useState({});
    let navigate = useNavigate();
    useEffect(() => {
        console.log('call customer useEffect');
        ReportObj.getList({ page: paging.currentpage, limit: paging.itemlimit, filter: filter }).then(list => {
            setReportList(() => {
                return { totalrecord: list?.collection?.count, list: list?.collection?.rows, uploadurl: list.uploadurl };
            })
        });
    }, [paging]);

    useEffect(() => {
        async function getLayers() {

            const list = await ReportObj.getCollectionLayer({});
            console.log(list);
            setLayers(list);
        }

        async function getCurrentUser() {

            const user = await AuthObj.getUserSession();
            console.log('User');
            console.log(user);
            setCurrentUser(user?.user);
         //   setLayers(list);
        }
        getCurrentUser();
        getLayers();
        getSection();
        getFeederList();
    }, []);
    
// For show Name in List
    async function getFeederList() {
        const flist = await CustomerObj.getkvfeeder11({});
          
          let filterdata=  flist.reduce((pre,current)=>{
              pre[current?.id]=current;
            return pre;

            },{});
          
            setfeederdata(filterdata);
            
       
      }
    async function getSection() {
        const sectionlist = await CustomerObj.getSection({});
  
  
        
        const worktypefiletrlist = sectionlist.map((item) => {
          return { value: item.id, label: `${item.section_name} (${item.section_code})` }
  
        })

        setsection({list:sectionlist,options:worktypefiletrlist});
        
      }

      async function getkvfeeder33(section_id) {
        const kvfeeder33list = await CustomerObj.getkvfeeder33({sectionid:section_id});             
        setkv33(kvfeeder33list);
      }

      async function getkvfeeder11(kvfeeder33_id) {
        const kvfeeder11list = await CustomerObj.getkvfeeder11({kvfeeder33_id:kvfeeder33_id});       
        setkv11(kvfeeder11list);
      }

      async function getTransformer(kvfeeder11_id) {
        const transformerlist = await CustomerObj.getTransformer({kvfeeder11_id:kvfeeder11_id});
        setdtlist(transformerlist);
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        setpage((pre) => {
            return { ...pre, currentpage: 1 }
        })
    
    }

   

 

    const handleChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(name=='section_id'){
            setkv33([]);
            setkv11([]);
            setdtlist([]);
            setFilter((pre) => {                
                let newobj = {};
                newobj[name] = value;
                newobj['kvfeeder33_id'] = '';
                newobj['kvfeeder11_id'] = '';
                newobj['dt_id'] = '';
                
                console.log({ ...pre, ...newobj });

                return { ...pre, ...newobj }
    
            })
            
          await getkvfeeder33(value);
          return true;
        }

        if(name=='kvfeeder33_id'){         
            setkv11([]);
            setdtlist([]);

            setFilter((pre) => {                
                let newobj = {};
                newobj[name] = value;               
                newobj['kvfeeder11_id'] = '';
                newobj['dt_id'] = '';
                console.log({ ...pre, ...newobj });
                return { ...pre, ...newobj }
    
            })
          await getkvfeeder11(value);
          return true;
        }
        if(name=='kvfeeder11_id'){         
           
            
            setdtlist([]);
            setFilter((pre) => {
                console.log(pre);
                let newobj = {};
                newobj[name] = value;
                newobj['dt_id'] = '';
                return { ...pre, ...newobj }
    
            })
            await getTransformer(value);
            return true;
          //await getkvfeeder33();
        }

        

        

        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }

        })
    }


   
  




    const reassignClickHandel = async() => {
       if(typeof(filter['kvfeeder11_id'])=='undefined' || filter['kvfeeder11_id']==''){
        alert("please select 11 KV");
        return false;
       }
       setloader(true);
       const kvfeeder33list = await CustomerObj.reassign({kvfeeder11_id:filter['kvfeeder11_id']});             

       setloader(false);
       notification.success({message:"Reassign Successfully"});



    };

   

    const onSelect = (value,item) => {
           console.log(`Value ${value} index ${item}`);
           console.log(item);
        let name = 'user_id';
      
        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = item?.user?.id;
            return { ...pre, ...newobj }

        })
        console.log('onSelect', value);

    };


 



    return (
        <>
            <div class="callout callout-info bg-white">

                <form class="row align-items-center" onSubmit={(e) => { handleSubmit(e) }}>
               
                    <div class="col-md-2" style={{display:"none"}}>
                        <label><strong>Layer</strong></label>
                        <select className="form-control" name="ext_id" onChange={(e)=>handleChange(e)}>
                            <option value="">Select </option>
                            {layers.map((item) => {

                                return (<option value={item.id}>{item.name}</option>)
                            })}
                        </select>
                    </div>
                  
                  

                    <div class="col-md-2">
                        <label><strong>Section</strong></label>
                        <div class="form">
                            <select class="form-select" name="section_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose Section</option>
                                {section.list.map((item)=>{

                                    return <option value={item.id}>{item.section_name}</option>
                                })}                              
                            </select>
                        </div>
    </div>
    <div class="col-md-2">
                        <label><strong>33 KV</strong></label>
                        <div class="form">
                            <select class="form-select" name="kvfeeder33_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose 33KV</option>
                                {kv33.map((item)=>{
                                    return ( <option value={item.id} key={item.id}>{`${item.feeder_name} (${item.feeder_code})`}</option>)
                                })}                                
                            </select>
                        </div>
    </div>

    <div class="col-md-2">
                        <label><strong>11 KV</strong></label>
                        <div class="form">
                            <select class="form-select" name="kvfeeder11_id" onChange={(e) => handleChange(e)}>
                                <option value="">Choose 11 KV</option>
                                {kv11.map((item)=>{
                                    return ( <option value={item.id} key={item.id}>{`${item.feeder_name} (${item.feeder_code})`}</option>)
                                })}  
                            </select>
                        </div>
    </div>

    <div class="col-md-2" >
                        <label><strong></strong></label>
                        <div class="form">
                          <Link to="" className="btn btn-primary" style={{marginRight:10}} onClick={(e)=>reassignClickHandel()}>Reassign</Link>
                          {loader==true && <Spin></Spin>}
                        </div>
    </div> 
   
    



                  
  
                    
                </form>
            </div>
            </>
    )

}

export default Reassign;