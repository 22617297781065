import { useEffect, useState, useCallback } from 'react';
import config from '../../config/index';
import AuthObj from '../../utility/authclass';
import GoogleMapReact from 'google-map-react';
//import Marker from './Marker';
//import { Polyline } from '@react-google-maps/api';
import { GoogleMap, useJsApiLoader, Marker, Polyline } from '@react-google-maps/api';
import ReportObj from '../../utility/reportclass';
import CustomerObj from '../../utility/customerclass';
import { Button, Pagination, Popconfirm, notification, Modal, Empty, AutoComplete, Input, DatePicker, Drawer,Select,Spin } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined, InfoCircleFilled,FilterOutlined,CloseCircleOutlined,CheckCircleOutlined } from '@ant-design/icons';
import common from '../../utility/commonclass';
import { useNavigate, Link } from "react-router-dom";
import Filter from './Filter';
import MyComponent from './Map';
const photofield = ["tp_uq_pole_photo", "tp_uq_pole_paint_photo"];
const { RangePicker } = DatePicker;

const containerStyle = {
    width: '100%',
    height: '90vh'
};


const MapReport = () => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAgIVtPj9I-jXY3fOUkV8k9CArQRQ7dkhc"
    })

    const [map, setMap] = useState(null);
    const [customerList, setReportList] = useState({ totalrecord: 0, list: [], uploadurl: "" });
    const [mapItems, setmapItems] = useState([]);
    const [selectedLayers, setselectedLayers] = useState([]);
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 500 });
    const [filter, setFilter] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageData, setImageData] = useState({ url: "", list: [] });
    const [useroptions, setuseroptions] = useState([]);
    const [search, setSearch] = useState("");
    const [loader, setloader] = useState(false);
    const [downloadtype, setdownloadtype] = useState("geojson");
    const [currentUser, setCurrentUser] = useState({});
    const [section, setsection] = useState({ list: [], options: [] })
    const [layers, setLayers] = useState([]);
    const [kv33, setkv33] = useState([])
    const [kv11, setkv11] = useState([])
    const [dtlist, setdtlist] = useState([])
    const [open, setOpen] = useState(false);
    const [openElement, setopenElement] = useState(false);
    const [elementDetail, setelementDetail] = useState({});
    const [elementDetailImages, setelementDetailImages] = useState([]);
    const [formlist, setformlist] = useState([]);
    const [formValue, setformValue] = useState({});
    const [islistshow, setislistshow] = useState(false);   
    const [center, setCenter] = useState({ lat: 19.319437, lng: 84.796627 });
    const [zoom, setzoom] = useState(5);
    const [label, setlabel] = useState({});
    const [filterOpen, setFilterOpen] = useState(false);
    const [addlayer, setaddlayer] = useState(0);
    let [elementdetailloader, setelementdetailloader] = useState(0);
    let [surveyid, setsurveyid] = useState(0);
    let [surveyindex, setsurveyindex] = useState(0);
    const defaultProps = {
        center: center,
        zoom: zoom
    };

    const [detail, setDetail] = useState({ collection: {}, url: "" });
    const [Transformerdetail, setTransformerdetail] = useState({});
    const [TransformerPoles, setTransformerPoles] = useState([]);
    const [selectedPole, setSelectedPole] = useState("");
    let navigate = useNavigate();

    const onLoad = useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(defaultProps?.center);
        //  map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    useEffect(() => {
        // console.log('google map');
        // console.log(GoogleMapReact);
        async function getLayers() {

            const list = await ReportObj.getCollectionLayer({});
          
            setLayers(list);
        }

        async function getCurrentUser() {

            const user = await AuthObj.getUserSession();
            console.log('User');
            console.log(user);
            setCurrentUser(user?.user);
            //   setLayers(list);
        }
        getCurrentUser();
        getLayers();
        getSection();
    }, []);

    useEffect(()=>{   
        document.addEventListener('keydown', handleKeyDown); 
        return () => document.removeEventListener("keydown", handleKeyDown);
    
    },[openElement]);
    
    function handleKeyDown(e) {
   
 
        console.log(openElement);
        console.log(surveyindex);   
        if(openElement==true){
            if(e.keyCode==39){  // for next
               
           // console.log("next");
            //console.log(customerList?.list?.length+"-"+surveyindex);      
        
                if(customerList?.list?.length > surveyindex){
                    surveyindex= surveyindex+1;
                    let sid=customerList?.list[surveyindex]?.id;
                    console.log(`id ${sid}`);
                    if(common.isEmpty(customerList?.list[surveyindex])==false){
                      let codd=  getlocation(customerList?.list[surveyindex]);
                      setCenter(codd);
                      openElementDetail(sid,surveyindex);
                    }
                   
                  //  console.log(`surveyid${sid}`); 
        
                   
                }
        
            }else if(e.keyCode==37){ // pre
               
                //console.log("next");
                //console.log(surveyindex);      
        
                if( surveyindex >0){
                    surveyindex= surveyindex-1;
                    let sid=customerList?.list[surveyindex]?.id;
                    let codd=  getlocation(customerList?.list[surveyindex]);
                    setCenter(codd);
                  //  console.log(`surveyid${sid}`); 
        
                    openElementDetail(sid,surveyindex);
                }
            }
            }
    
    
        
    }


    function getlocation(item) {
        try{
          if(item?.coordinates =='undefined'){
              return { lat: 0, lng: 0 }
          }
       
        let coordinates = JSON.parse(item?.coordinates);
       // console.log({ lat: coordinates[0]['latitude'], lng: coordinates[0]['longitude'] });
        return { lat: parseFloat(coordinates[0]['latitude']), lng: parseFloat(coordinates[0]['longitude']) }
        }catch{
          return { lat: 0, lng: 0 }
  
        }
        
      //return location.lat + location.lng
    }

    async function getSection() {
        const sectionlist = await CustomerObj.getSection({});



        const worktypefiletrlist = sectionlist.map((item) => {
            return { value: item.id, label: `${item.section_name} (${item.section_code})` }

        })

        setsection({ list: sectionlist, options: worktypefiletrlist });

    }

   

    const getResults = async (filter) => {
       
        if (Object.keys(filter).length == 0) {

            notification.error({ key: 'filter', message: 'Please Select Any Filter' });
            return false;
        } else if (common.isEmpty(filter?.section_id) == false && common.isEmpty(filter?.kvfeeder33_id)) {
            notification.error({ key: 'filter', message: 'Please Select 33 KV Filter' });
            return false;
        }
        setmapItems([]);

        let list = await ReportObj.getListItem({ filter: filter, paging: false });
        
        setReportList(() => {
            return { totalrecord: list?.collection?.length, list: list?.collection, uploadurl: list.uploadurl };
        });

        let mapi = list?.collection.filter((item) => {

            return selectedLayers.includes(item.ext_id) == true;
        });
        await setmapItems(mapi);
    }
    
    
    const handlePageChange = (page) => {

        setpage((pre) => {
            return { ...pre, currentpage: page }
        })
    }
    const handleDelete = async (e, id, key) => {
        try {
            console.log(id);
            const customerinfo = await ReportObj.deleteRecord({ id: id });
            customerList.list.splice(key, 1);
            let newarr = customerList.list;
            console.log(newarr);
            setReportList((preList) => {
                return { totalrecord: customerList.totalrecord - 1, list: customerList.list };
            })

        } catch (err) {
            notification.error({ message: err.message });

        }

    }

    const haldelEdit = (e, id) => {
        navigate('/admin/report/list/' + id);

    }

    const ImageField = (items) => {
        const photoData = items.filter((item) => {

            return photofield.indexOf(item.field_name) !== -1;
        })

        console.log(photoData);
        return (<>
            {photoData.map((photoItem) => {
                return (<><p><a href={`${customerList.uploadurl}${photoItem.field_value}`} target="_blank">{photoItem.field_label}</a></p></>)

            })}
        </>);
    }

    const openImage = async (id) => {
        setImageData({ url: "", list: [] });
        const imageData = await ReportObj.getImageFields({ id: id });
        console.log(imageData);

        setImageData({ url: imageData.UPLOADPATH, list: imageData.fields });

        setIsModalOpen(true);
    }



    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const downloadData = async (e) => {

        //console.log(filter);
        // console.log(downloadtype);

        let fliteropt = { ...filter };
        fliteropt['fileType'] = downloadtype;
        let qtr = new URLSearchParams(fliteropt).toString()
        console.log(qtr);
        console.log('Download');
        console.log(config.url + 'downloadreport/?' + qtr)        
        window.location.replace(config.url + 'downloadreport/?' + qtr);

    }
    const showDrawer = () => {
        console.log('asdasd');
        setOpen(true);
    };

    const showFilter = (t) => {
        
        setFilterOpen(t);
    };
    
    const onClose = () => {
        setOpen(false);
    };
    const handleOnChangeLayer = async (event, id) => {
        console.log(id);
        //if()
        let items = [];
        if (event.target.checked) {
            items = [...selectedLayers];
            items.push(id);
            await setselectedLayers(items);

        } else {
            items = [...selectedLayers];
            const index = items.indexOf(id);
            const x = items.splice(index, 1);
            await setselectedLayers(items);
        }
        console.log('selectedLayers');

        console.log(selectedLayers);

        let mapi = customerList?.list.filter((item) => {

            return items.includes(item.ext_id) == true;
        })
        setmapItems(mapi);

       




    };


    const handleOnChangeLayerLabel = async (event, id) => {


        if (event.target.checked) {
            
            if(common.isEmpty(label[id])==true){
                label[id]={}
            }
            label[id]['status']=true;           
            if(common.isEmpty(label[id]['type'])==true){
                label[id]['type']=['code'];
            }
             
            console.log(label);         
            await setlabel(label);
          

        } else {          
            label[id]['status']=false;
            //label[id]['type']=[];  
            await setlabel(label);
            
        }

    }

    const handleLabelType = async (value, id) => {
       
        if(common.isEmpty(label[id])==true){
            label[id]={}
            label[id]['status']=false;

        }

        label[id]['type']=value; 
        console.log(label);         
        setlabel(label);

    }

 
const imageView=(url)=>{
    let newwindow=window.open(url,"imageopen",'height=500,width=500');
    if (window.focus) {newwindow.focus()}
    return false;
   
}


    const openElementDetail = async (key, sindex) => {
        setelementdetailloader(0);
    setsurveyid(key);
    console.log(`pp index ${sindex}`);
    console.log(`pp key ${key}`);
   await setsurveyindex(sindex);
 
    let collectionDetail = await ReportObj.getCollectionDetail({ id: key });
    setelementDetail(collectionDetail.collection);
    //setelementDetailImages();
        let images=[];

    let formval = collectionDetail?.collection?.surveyitems.reduce((items, current) => {
        items[current?.field_name] = current;           
        return items;
    }, {})
    console.log(formval);
    setformValue(formval);
    let formlist = await ReportObj.getCollectionLayerForm({ ext_id: collectionDetail?.collection?.ext_id });
        for(let i = 0; i<formlist.length;i++){
           // console.log(formlist[i]);
            if(formlist[i]?.data_type=='Image'){
                images.push(formlist[i]) ;
            }

        }
        console.log(images);

    setelementDetailImages(images);
    setformlist(formlist);

    if(collectionDetail?.collection?.ext_id==14){

        let TransformerPole = await ReportObj.getTransformerPole({ code: collectionDetail?.collection?.mobile_record_number });
            setTransformerdetail(TransformerPole);
            if (common.isEmpty(TransformerPole?.ht_pole_code) == true) {
                let TransformerPoleList = await ReportObj.getTransformerPoleList({ kvfeeder11_id: TransformerPole?.kvfeeder11_id, coordinates: collectionDetail?.collection?.coordinates });
                setTransformerPoles(TransformerPoleList);
            }

    }

    setelementdetailloader(1);
    setopenElement(true);
    }

    const saveTransformerpole =async (id)=>{
        console.log(id);
        console.log(selectedPole);
        if(common.isEmpty(selectedPole)==true){
            notification.error({message:"Please Select Pole"});
            return false;
        }
      
        if(selectedPole.includes("Tmp-")===true){
            notification.error({message:"This Pole have not generate Migration Id yet"});
            return false;
        }

        console.log("Saved");
        let data = await ReportObj.saveTransformerpole({ ht_pole_code:selectedPole, id: id });
       
     
            notification.error({message:"Pole has beed saved successfully"});
        
       

    }

    const updatelement=async(e)=>{

        console.log(formValue); 
        console.log("Saved");
        let data = await ReportObj.SaveSurveyFormData({ formdata:formValue, id: surveyid });
        notification.error({message:"Form data has been updated successfully"});
        
    
    }
    const setvaue =(e,key)=>{
        let item=formlist[key];
        console.log(formValue);
        console.log(item);
      //  formValue[item.field_name]?.field_value
      if(typeof( formValue[item.field_name])=='undefined'){
        formValue[item.field_name]={}
      }
      formValue[item.field_name].field_value=e.target.value;
      //console.log(formValue[item.field_name].field_value);
      setformValue((pre)=>({...pre,[item.field_name]:formValue[item.field_name]}));
       // console.log(formValue[item.field_name]?.field_value);
    }


    const savelayer =async(data)=>{      
        setReportList((pre)=>({...pre ,totalrecord:pre?.totalrecord+1,list:[...pre?.list,data] }));
       



    }
    

    return (
        <>
        <Filter visible={filterOpen} currentUser={currentUser} totalrecord={customerList.totalrecord} showFilter={showFilter} getResults={getResults} downloadData={downloadData}></Filter>
            <Drawer
                title="Layer List"
                placement="right"
                closable={false}
                onClose={(e) => onClose()}
                getContainer={false}
                visible={open}
                key="drawerlayer"
                forceRender={true}
            >
                <ul className="layer-ul">
                    <li>
                        <label className="name-box">Name</label>                       
                        <label className="layer-box">Layer</label>
                        <label className="label-box">Label</label>
                        
                    </li>
                  
                    {layers.map((item, itemkey) => {

                        return (<li key={item.id}>
                             <label className="name-box">{item.name}</label> 
                            
                            <label className="layer-box"><input type="checkbox" value={item.id} onChange={(e) => handleOnChangeLayer(e, item.id)}></input></label>
                            <label className="label-box">
                                <input type="checkbox" onChange={(e) => handleOnChangeLayerLabel(e, item.id)}></input>
                                

                              
                                
                                </label>
                                <label className="labelvalue-box">
                                <Select name="labeltype" className="" onChange={(e)=>handleLabelType(e, item.id)} 
                                    mode="multiple"
                                    allowClear
                                    style={{
                                      width: '100%',
                                    }}
                                    placeholder="Please select"
                                    
                                 >
                                    <option value="code">Migration Id </option>
                                    <option value="paint_code">Paint Code</option>
                                    <option value="nominal_voltage"> Nominal voltage</option>
                                    <option value="composite_type"> Composite Type</option>  
                                    <option value="element_type"> Element Type</option>                                   
                                    <option value="no_of_hv">No of HV</option>  
                                    <option value="no_of_mv">No of MV</option>  
                                    <option value="no_of_lv">No of LV</option>  
                                    <option value="no_branch">Branch</option>  
                                    <option value="jumper">Jumper</option> 
                                    <option value="cut_point">Cut Point</option>  
                                   

                                    
                                    </Select>
                                </label>

                        </li>)
                    })}

                </ul>
            </Drawer>

                    
            <div
                title="Element Detail"
                placement="right"
                closable={false}
                onClose={(e) => setopenElement(false)}
                getContainer={false}
                visible={openElement}
                key="drawerElement"
                width="50%"
                className={`elementdetail ${(openElement==true && "active")}`}
            >
<div className="closebtn">
    <Link to="" onClick={(e)=>setopenElement(false)} className="btn btn-primary btn-sm">X</Link>
</div>
              
                    {elementdetailloader==0 && <p style={{textAlign:'center'}}><Spin></Spin></p>}
                {elementdetailloader==1 && Object.keys(elementDetail).length > 0 && <div className="card-body">
                    
                    {elementDetail?.isedited==1 && <div className="edited-icon"><CheckCircleOutlined></CheckCircleOutlined>
                    {common.datetimeFormat(elementDetail?.update_date)}
                    </div>}
                    
                    <div className="card mb-12">
                        <div className="card-header">Images   ({`Survey Index ${surveyindex}`}) {currentUser?.role == 1 && <Popconfirm placement="bottom" title="Do You Want To Delete ?" onConfirm={(e) => handleDelete(e, surveyid, surveyindex)} okText="Yes" cancelText="No">
                                                            <Button icon={<DeleteOutlined />} className="float-right btn btn-dark btn-sm delete-btn">Delete</Button>
                                                        </Popconfirm>  
                                                        } &nbsp; <Link to="" onClick={(e)=>updatelement(e)} className="btn btn-primary btn-sm float-right">Save</Link></div>
                        <div className="card-body">
                        <div className="container"> 
                        <ul class="image-container">                    
                        {elementDetailImages.map((eleImage)=>{
                            return (<li><Link to="" onClick={(e)=>imageView(formValue[eleImage.field_name]?.s3)}>
                            <label for="staticEmail" class=" col-form-label">{eleImage.field_label}</label>
                            <div >
                                <img src={`${formValue[eleImage.field_name]?.s3}`}></img>
                            </div>
                            </Link>
                        </li>)

                        })
                        }</ul>   
                            </div>
                        </div>
                    </div>

                   <div className="scroll-body">
                    {elementDetail?.ext_id == 14 && <>
                            <br />
                            <div className="card mb-12">
                                <div className="card-header">Pole Detail</div>
                                <div className="card-body">
                                    <div className="container">
                                        <div class="form-group row">
                                            <label for="staticEmail" className="col-sm-2 col-form-label">Pole Code</label>
                                            {common.isEmpty(Transformerdetail?.ht_pole_code) == true && <><div className="col-sm-5"><select className="form-control" onChange={(e)=>{setSelectedPole(e.target.value)}}>
                                                <option value="">Select Pole</option>
                                                {TransformerPoles.map((item) => {
                                                    let code=common.isEmpty(item['record_number'])==true?'Tmp-'+item['mobile_record_number']:item['record_number'];
                                                   return( <option value={code}>{code}</option>);
                                                })}
                                            </select>
                                          
                                            </div><div className="col-sm-2">  <button className="btn btn-primary" onClick={(e)=>saveTransformerpole(Transformerdetail?.id)}>Save</button></div></>}
                                            {common.isEmpty(Transformerdetail?.ht_pole_code) == false && <div className="col-sm-5">{Transformerdetail?.ht_pole_code}</div>}
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </>}

                        <br />
                  
                    <div className="card mb-12">
                        <div className="card-header">Form Information </div>
                        <div className="card-body">
                            <div className="container">
                                {formlist.map((item, itemkey) => {

                                    if (item.data_type == 'ENUM') {
                                        let options = item.field_values.split(",");

                                        return (<div class="form-group row">
                                            <label for="staticEmail" class="col-sm-4 col-form-label">{item.field_label}</label>
                                            <div class="col-sm-5">
                                                <select name={item.field_name} className="form-control"  >
                                                   <option value="">Select</option>
                                                    {options.map((optionitem) => {
                                                        let selected = "";
                                                        if (formValue[item.field_name]?.field_value == optionitem) {
                                                            selected = "selected";
                                                        }
                                                        return <option value={optionitem} selected={selected}>{optionitem}</option>
                                                    })}
                                                </select>


                                            </div>
                                        </div>)


                                    } else if (item.data_type == 'Image') {
                                        {/*return (<div class="form-group row">
                                            <label for="staticEmail" class="col-sm-4 col-form-label">{item.field_label}</label>
                                            <div class="col-sm-5">
                                                <img src={`${formValue[item.field_name]?.s3}`}></img>
                                            </div>
                                    </div>)*/}
                                    return (<></>)
                                    } else {
                                        return (<div class="form-group row">
                                            <label for="staticEmail" class="col-sm-4 col-form-label">{item.field_label}</label>
                                            <div class="col-sm-5">
                                                <input type="text" name={item.field_name} className="form-control" value={formValue[item.field_name]?.field_value} onChange={(e)=>setvaue(e,itemkey)}></input>

                                            </div>
                                        </div>)
                                    }


                                })}
                            </div>
                            <div class="form-group row"><label for="staticEmail" class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-5">
                                    {/*<Link className="btn btn-primary" to="" >Save</Link>*/}
                                </div></div>

                        </div>
                    </div>
                    <br/>
                    <div className="card mb-12">
                        <div className="card-header">User Information</div>
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                    </tr>
                                </thead>
                                <tbody>
                                     <tr>
                                        <td> {`${elementDetail?.customer?.first_name} ${elementDetail?.customer?.last_name}`}</td>
                                        <td> {`${elementDetail?.customer?.email} `}</td>
                                        <td> {`${elementDetail?.customer?.mobile} `}</td>
                                    </tr>
                                </tbody>
                            </table>

                           

                        </div>
                    </div>
                    <br />
                    <div className="card mb-12">
                        <div className="card-header">Location Information</div>
                        <div className="card-body">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-2 col-form-label">coordinates</label>
                                <div class="col-sm-10">
                                    {`${elementDetail?.coordinates} `}
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-2 col-form-label">Layer Name</label>
                                <div class="col-sm-10">
                                    {`${elementDetail?.objectextname?.name} `}
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-2 col-form-label">Application Save Date</label>
                                <div class="col-sm-10">
                                    {`${elementDetail?.app_save_date} `}
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-2 col-form-label">Code</label>
                                <div class="col-sm-10">
                                    {`${elementDetail?.mobile_record_number} `}
                                </div>
                            </div>


                        </div>
                    </div>
                    </div>
                </div>}

            </div>
                 
            <section className="map-section">
                <div className="layer-btn">
                    <Button type="primary" onClick={showDrawer}>
                        Layer
        </Button>
                </div>

                <div className="filter-btn">
                    <Button type="primary" onClick={()=>showFilter(true)}>
                        <FilterOutlined></FilterOutlined>
                     </Button>
                </div>
                <div className="add-layer-btn">
                   <select className="form-control" onChange={(e)=>{setaddlayer(e.target.value)}}>
                       <option value="0">Select layer Form Add</option>
                       <option value="2">Pole</option>
                       <option value="3">Cabel</option>
                       <option value="17">Wire</option>
                       <option value="14">DT</option>
                       </select>
                </div>        

        <MyComponent savelayer={savelayer} addlayer={addlayer} list={customerList?.list} label={label} openElementDetail={openElementDetail} center={center} selected={surveyid} ></MyComponent>

            </section>
            <div className="card mb-4">
                <div className="card-header">Collection List</div>
                <div className="card-body">
                    Is List Show <input type="checkbox" onChange={(e) => {
                        if (e.target.checked) {
                            setislistshow(true);
                        } else {
                            setislistshow(false);
                        }

                    }}></input>
                    <div className="tab-content rounded-bottom">
                        {islistshow == true && <table className="table table-striped table-hover">
                            <thead>
                                <tr key={0}>
                                    <th >#</th>
                                    <th>User</th>
                                    <th>Project</th>
                                    <th>Photo</th>
                                    <th>Number</th>
                                    <th>Layer</th>
                                    <th>Status</th>
                                    <th>Server Date</th>
                                    <th>Survey Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    islistshow == true && mapItems.map((item, itemKey) => {
                                        return (
                                            <tr key={item.id}>
                                                <td >{((paging.currentpage - 1) * paging.itemlimit) + (itemKey + 1)}</td>

                                                <td >{`${item?.customer?.first_name} ${item?.customer?.last_name}`}</td>
                                                <td >{`${item?.project?.project_name}`}</td>
                                                <td><button onClick={() => openImage(item.id)}>View Photo</button></td>
                                                <td >{(item.record_number) ? item.record_number : 'Temp : ' + item.mobile_record_number}</td>
                                                <td>{item?.objectextname?.name}</td>
                                                <td>{(item.status == 1) ? 'Active' : 'Inactive'}</td>
                                                <td >{common.datetimeFormat(item.date)}</td>
                                                <td >{common.datetimeFormat(item.app_save_date)}</td>
                                                <td >
                                                    {currentUser.role != 3 && <>
                                                        <Button icon={<EditOutlined />} onClick={(e) => haldelEdit(e, item.id)}>Edit</Button> &nbsp;

                                                {currentUser?.role == 1 && <Popconfirm placement="bottom" title="Do You Want To Delete ?" onConfirm={(e) => handleDelete(e, item.id, itemKey)} okText="Yes" cancelText="No">
                                                            <Button icon={<DeleteOutlined />}>Delete</Button>
                                                        </Popconfirm>
                                                        }
                                                    </>}
                                                </td>

                                            </tr>

                                        )

                                    })
                                }

                            </tbody>
                        </table>}


                        <Modal title="Images" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                            {imageData.list.map((item) => {
                                let imageUrl = "";
                                if (common.isEmpty(item.s3) == false) {
                                    imageUrl = item.s3;
                                } else {
                                    imageUrl = `${imageData.url}${item.field_value}`;
                                }

                                if (currentUser.role != 3) {
                                    return (<><a href={imageUrl} target="_blank"><img style={{ width: 200 }} src={imageUrl}></img></a><p>{item.field_label}</p></>)
                                } else {
                                    return <><img style={{ width: 200 }} src={imageUrl}></img><p>{item.field_label}</p></>
                                }

                            })}

                            {imageData.list.length == 0 && <Empty></Empty>}
                        </Modal>

                    </div>
                </div>
            </div>

        </>
    )

}

export default MapReport;